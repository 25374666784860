.elem-scroll-to-top {
    display: none;
    position: fixed;
    bottom: 122px;
    right: 25px;
    z-index: setZ(backToTop);

    &.show {
        display: block;
    }

    &__button {
        border-radius: 50%;
        width: 56px;
        height: 56px;
        text-align: center;
        background-color: $white; 
        box-shadow: 0 8px 16px 4px rgba(38,41,104,0.16);

        .icon-arrow_up {
            &:before {
                color: $blue;
            }
        }

        @include mouse-only() {
            &:hover,
            &:focus  {
                //background: rgba(0,0,0,0.05);
                padding-bottom: 10px;
                transition: all .3s ease;
            }
            &:not(:hover) {
                transition: all .3s ease;
            }
        }

        @include respond-above(lg) {
            width: 64px;
            height: 64px;

            .icon-arrow_up {
                //font-size: rem(34px);
                font-size: rem(18px);
                ;
            } 
        }
    }

    @include respond-above(lg) {
        bottom: 70px;
    }
}