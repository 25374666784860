/*************** IMAGE COVER POSITIONS LIBRARY ***************/

//USAGE (Default Top-Left)
//.bg-pos-X-Y


// Horizontal Position (X)
// Left
// Center
// Right

// Vertical Position (Y)
// Top
// Center
// Bottom




// naming delimiters
$delimiter1: '-';
$delimiter2: '-';


@for $i from 0 through 2 {
    $x: $i * 50;
    $X: if($x == 0, left, if($x == 100, right, if($x == 50, center, $x)) );

    @for $j from 0 through 2 {
        $y: $j * 50;
        $Y: if($y == 0, top, if($y == 100, bottom, if($y == 50, center, $y)) );

        .bg-pos#{$delimiter1}#{$X}#{$delimiter2}#{$Y} img {
            left: if($x != 0, #{$x}#{'%'}, $x) !important;
            top: if($y != 0, #{$y}#{'%'}, $y ) !important;
            transform: translate( if($x != 0, -#{$x}#{'%'}, $x), if($y != 0, -#{$y}#{'%'}, $y)) !important;
        }

    }
}

#{$edit_mode} {
    [class*='bg-pos-'] img {
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}