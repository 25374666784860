.widget-donut-graph {
    $this: &;

    &__container {
        //@include container();
        //@include content-container();
    }

    &__title {
        padding-top: 40px;

        h2 {
            @include karbonMedium();
            font-size: rem(24px);
        }
    }

    &__canvas {
        display: none;
        position: relative;
        width: 100%;
        height: 100%;

        .init & {
            display: block;
            padding-top: 50px;

            @include respond-above(lg) {
                //padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        canvas {
            //font-kerning: normal;
            //text-rendering: optimizeLegibility;
            letter-spacing: 2px;
        }
    }

    &__legend {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 40px;
        margin-top: 40px;

        li {
            display: flex;
            align-items: center;
            padding: 2px;

            span {
                display: inline-block;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                margin-right: 5px;
            }

            p {
                @include karbonMedium();

                margin: 0;
                padding: 0;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: $black;
            }
        }

        @include respond-above(md) {
            display: none;
        }
    }

    &.init {

        #{$this}__fallback-img,
        #{$this}__message {
            display: none;
        }
    }

    &__message {
        padding: 20px 0;
        color: black;
        font-size: 16px;
    }

    &__mobile-text {
        @include respond-above(md) {
            display: none;
        }

        #{$this}__mobile-label {
            font-size: rem(16px);
            color: black;
        }
    }
}