.elem-title {

    .base-main__item > & {
        width: 100%;
        padding-right: rem(25px);
        padding-left: rem(25px);
        margin: 0 auto;
        margin-top: rem(40px);

        @include respond-above(lg) {
            margin-top: rem(60px);
            padding-right: 5vw !important;
            padding-left: 5vw !important;
            max-width: calc( 1140px + 10vw );
        }

        @include respond-above(xl) {
            padding-right: 10vw !important;
            padding-left: 10vw !important;
            max-width: calc( 1140px + 20vw );
        }
    }
   
    .base-main__item .base-main__item > & {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: none;
        margin-top: 0;
    }

    width: 100%;
    color: $black;

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }

    &__h1 {
        @include t-h1-title();
    }

    &__h2 {
        @include t-h2-title();
    }
    
    &__h3 {
        @include t-h3-title();
    }

    &__h4 {
        @include t-h4-title();
    }

    &__h5 {
        @include t-h5-title();
    }

    .panel-blue & {
        color: $white;
    }

    .elem-panel-card & {
        padding: 0 rem(24px) rem(24px);

        @include respond-above(lg) {
            padding: 0 0 rem(24px) 0;
        }
    }

    & + .widget-tile {
        margin-top: 16px;

        @include respond-above(lg) {
            margin-top: 20px;
        }
    }
}