.elem-table {
   color: $black;

   &__table {
      width: 100%;
      border-collapse: collapse;
	   empty-cells: show;
   }

   &.column-regular {
      @include respond-above(lg) {
         col {
            width: 16.6666666667%;
   
            &:first-child {
               width: auto;
            }
         }
      }
   }
  

   td, th {
      text-align: left;
   }

   td {
      padding: rem(8px);
      font-size: rem(16px);
      line-height: rem(22px);
      letter-spacing: -0.5px;

      &:first-child {
         border-right: 1px solid $lighterGray;
      }
      
      @include respond-above(lg) {
         padding: rem(16px);
         font-size: rem(24px);
         line-height: rem(32px);

         &:first-child {
            border: 0;
         }
      }
   }

   th {
      @include t-label-big();
      font-weight: normal;
      padding: rem(16px) rem(8px);

      @include respond-above(lg) {
         font-size: rem(14px);
         line-height: rem(24px);
         padding: rem(32px) rem(16px);
      }
   }

   thead {
      border-bottom: 1px solid $lighterGray;
      background: transparent;
   }

   tbody {
      tr {
         &:nth-child(even){
            background: $pageBg;
         }
         &:nth-child(odd) {
            background: $white;
         }
      }
   }


   &-responsive {
      border-top: 1px solid $lighterGray;

      @include respond-above(lg) {
         border-top: 0;
      }
      
      thead {
         display: none;
         
         @include respond-above(lg) {
            display: table-header-group;
         }
      }

      tbody tr:last-child:not(.elem-table__footRow) {
         border-bottom: 1px solid $lighterGray;
      }

      .elem-table__footRow {
         @include karbonMedium();

         tr {
            background: $pageBg;
         }

         th {
            @include karbonMedium();
         }

         td:before {
            @include karbonRegular();
         }
      }

      @include respond-below(lg) {
         tr {
            display: flex;
            flex-flow: column wrap;
            padding: 24px;
         }

         th {
            padding: 0;
            margin-bottom: 24px;
         }

         td {
            display: flex;
            justify-content: space-between;
            align-self: center;
            width: 100%;
            padding: 0  0 16px 0 ;
            
            &:last-child {
               padding: 0;
            }

            &:before {
               content: attr(data-cell-header);
               display: inline-flex;
            }
         }

         tbody {
            th {
               color: $darkGray;
            }
         }

         .elem-table__footRow {
            th {
               color: $black;
            }
         }
      }

      @include respond-above(lg) {
         tbody {
            th {
               font-size: rem(24px);
               line-height: rem(32px);
               text-transform: none;
               letter-spacing: -0.5px;
            }
         }

         tbody {
            th {
               @include karbonRegular();
            }

            th, td {
               padding: rem(24px) rem(16px);
            }
         }

         .elem-table__footRow {
            border-top: 1px solid $black;

            td, th {
               padding: rem(34px) rem(16px);
            }
         }
      }
   }

   &__notes {
      line-height: rem(22px);
      margin-top: rem(24px);
      @include respond-above(lg) {
         font-size: rem(18px);
         line-height: rem(24px);
         margin-top: rem(32px);
      }

      .panel-blue & {
         color: $white;
      }
   }
}