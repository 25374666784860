.widget-bond-matrix {

	&__title {
		@include content-container();
		margin-bottom: 43px;

        @include karbonMedium();
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 24px;


		@include respond-below(lg) {
			margin-bottom: 32px;
		}
	}
    
    &__label {
        @include karbonMedium();
        color:$gray;
        font-size: rem(18px);
        letter-spacing: 2px;
        line-height: 24px;
        padding-bottom:4px;
        text-transform: uppercase;

            @include respond-below(lg) {
                font-size: rem(14px);
                letter-spacing: 2px;
                line-height: 14px;
                padding-bottom:9px;
            }
    }

    &__divider {
        height: 32px;
        border-bottom: 1px solid $lightGray;
        margin-bottom:32px;

        @include respond-below(lg) {
            // height:25px;
            // margin-bottom:24px;
            display: none;
            
        }
    }

    &__divider-mobile {
        height:25px;
        margin-bottom:24px;
        border-bottom: 1px solid $lightGray;

        @include respond-above(lg) {
            display: none;
            
        }
    }

    &__cta-container {
        color:$blue;
        padding-bottom: 56px;
        border-bottom: 1px solid $black;

        @include respond-below(lg) {
            padding-bottom: 40px;

        }
     }
}
