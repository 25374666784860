.elem-events {
    $this-events: &;
    width: 100%;

    &.p-b-def {
        padding-bottom: rem(32px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }

    &__wrapper {
        @include container();
        @include content-container();
    }

    &__content {
        padding: rem(24px) 0;
        margin: 0 rem(24px);
        display: flex;
        flex-direction: column;
        
        + #{$this-events}__content {
            border-top: 1px solid $lightGray;
        }

        @include respond-above(lg) {
            margin: 0;
            padding: rem(28px) 0 rem(31px) 0;
        }
    }

    &__content-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content-link{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: rem(10px);

        .elem-text--link-arrow {
            color:$blue;
        }

        @include respond-above(lg) {
            align-self: flex-end;
            margin-bottom: -5px;
        }
    }

    &__name {
        @include karbonRegular();
        color: $black;
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -1px;
        @include respond-above(lg) {
            letter-spacing: -0.5px;
        }
    }

    &__description {
        @include karbonRegular();
        color: $txtAlt;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-top: rem(8px);

        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: rem(18px);
            margin-top: rem(3px);
        }
    }
}


.elem-events_ctaBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;

        @include respond-above(lg) {
            display: none;
        }
}


