.elem-attachment__row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .elem-attachment__columnSx {
    float: left;
    width: 35%;
    padding-top: 64px;
    padding-right: 3%;
    background-color:transparent;

        @include respond-below(lg) {
            width:100%;
            padding-top:40px;
        }

        &-title{
            @include karbonRegular();
            color: $blue;
            font-size: rem(64px);
            letter-spacing: 0;
            line-height: 72px;

            @include respond-below(lg) {
                font-size: rem(40px);
                letter-spacing: -1px;
                line-height: 48px;
                padding-bottom: 24px;
            }
        }
  }

  .elem-attachment__columnDx {
    float: right;
    width: 65%;
    padding-top: 64px;
    background-color:transparent;

        @include respond-below(lg) {
            padding-top: 0;
        }

    &-description{
        @include karbonRegular();
        color: $black;
        font-size: rem(24px);
        letter-spacing: -0.5px;
        line-height: 32px;

        @include respond-below(lg) {
            font-size: rem(18px);
            letter-spacing: -0.5px;
            line-height: 24px;
            padding-bottom: 35px;
        }
    }

    @include respond-below(lg) {
        width:100%;
    }
  }


  