.elem-history-launch {
    background-color: $white;
    padding-top: rem(72px);

    @include respond-above(lg) {
        padding: rem(64px) 0 0 0;
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__title {
        @include t-h3-title();
        color: $blue;
    }

    &__item {
        margin-top: rem(32px);
        position: relative;

        @include respond-below(lg) {
            padding-left: 16.6666666667%;
            padding-bottom: rem(72px);
        }

        @include respond-above(lg) {
            @include make-row();
            margin-top: rem(56px);
        }
    }

    &__year-col {
        @include make-col-ready();

        @include respond-above(lg) {
            @include make-col(2);
            padding: 0;
        }
    }

    &__year {
        //@include nexiNumber();
        font-size: rem(32px);
        line-height: rem(40px);
        letter-spacing: -1px;
        color: $black;

        @include respond-above(lg) {
            text-align: right;
            font-size: rem(72px);
            line-height: rem(77px);
            letter-spacing: -2px;
        }
    }

    &__line-col {
        display: flex;
        justify-content: flex-start;

        @include respond-below(lg) {
            position: absolute;
            left: 0;
            top:0;
            width: 16.6666666667%;
            height: 100%;
            padding-top: rem(18px);
        }

        @include respond-above(lg) {
            @include make-col-ready();
            @include make-col(2);

            justify-content: center;
            margin-top: rem(33px);
            padding-left: 0;
        }
    }

    &__line {
        height: 100%;
        border-left: 2px solid #DFDFDF;
        position: relative;
        margin-left: rem(5px);

        @include respond-above(lg) {
            margin-left: 0;
            margin-right: rem(5px);
        }
    }

    &__dot {
        width: rem(11px);
        height: rem(11px);
        border-radius: 50%;
        background-color: $blue;
        position:absolute;
        left:-6px;

        @include respond-above(lg) {
            width: rem(12px);
            height: rem(12px);
            left:-7px;
        }
    }

    &__content-col {
        @include make-col-ready();
        
        margin-top: rem(16px);

        @include respond-above(lg) {
            @include make-col(8);

            margin-top: rem(26px);
            padding-bottom: rem(144px);
        }
    }

    &__label {
        text-transform: uppercase;
        color: $blue;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-bottom: rem(12px);

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: rem(19px);
            margin-bottom: rem(24px);
        }
    }

    &__description {
        color: $black;
        font-size: rem(16px);
        line-height: rem(22px);

        @include respond-above(lg) {
            font-size: rem(40px);
            line-height: rem(48px);
            letter-spacing: -1.5px;
        }
    }

    &__cta {
        color: $blue;
        margin-top: rem(23px);

        @include respond-above(lg) {
            margin-top: rem(30px);
        }
    }

}