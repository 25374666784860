.widget-past-events {
    margin-top: 65px;

    &__container {
        @include container();
        @include content-container();
    }

    .layout-panel &__container {
        padding: 0 !important;
        max-width: none;
    }

    &__tab-group {
        padding-top: 26px;
        display: flex;
    }

    &__btn {
        @include btn-tab();
    }

    &__tab-pane {
        display: none;
        color: $black;
        padding-bottom: 80px;
        
        &.active {
            display: block;
        }

        #{$wcmmode} {
            display: block;
        }
    }

    &__load-more {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }

        .elem-cta-load-more {
            margin-top: 32px;
        }
    }
}