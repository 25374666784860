
$contact-card: '[data-modal-type="contact-card"]';
$press: '[data-modal-type="press"]';
$two-step: '[data-modal-type="two-steps"]';
$filters: '[data-modal-type="filters"]';
$lang-switcher: '[data-modal-type="lang-switcher"]';
$video: '[data-modal-type="video"]';

$video-padding: 40px;

@keyframes bummer {
    100% {
        transform: scale(1,1); 
    }
}

// MODAL
.widget-modal {
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    //transition: opacity 400ms ease-in;
    z-index: -1;
    display: none;

    &#{$press},
    &#{$two-step} {
        background: $pageBg;
        animation: bummer 0.7s;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
        transform: scale(0,0);
    }

    &.show {
        z-index: setZ(modal);
        display: block;
        opacity: 1;
        pointer-events: auto;
    }

    &__backdrop {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        background-color: #000;
        opacity: 0.6;
        //transition: opacity 400ms ease-in;

        #{$press} &,
        #{$two-step} &,
        #{$edit_mode} & {
            display: none;
        }
    }
    
    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;

        #{$filters} & {
            background: $white;
        }

        @include respond-below(lg) {
            #{$video} & {
                height: auto;
            }
        }
        
        @include respond-above(lg) {
            margin: 85px auto;
            height: calc(100% - 170px);
            max-height: calc(100% - 170px);
            display: flex;
            align-items: center;
            pointer-events: none;

            #{$contact-card} &,
            #{$lang-switcher} & {
                width: 83.3333333333%;
                max-width: 996px;
            }

            #{$press} &,
            #{$two-step} & {
                margin: 0;
                height: 100%;
                max-height: 100%;
            }
        }
    }

    &__container {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        
        #{$contact-card} &,
        #{$lang-switcher} & {
            background-color: $white;
            position: relative;
        }

        @include respond-above(lg) {
            height: auto;
            max-height: 100%;
            flex: 1;

            #{$contact-card} &,
            #{$lang-switcher} & {
                border-radius: rem(16px);
            }

            #{$press} &,
            #{$two-step} & {
                height: 100%;
                padding: 114px 0 40px;
                justify-content: center;
            }

            #{$video} & {
                position: relative;
                width: 100%;
                max-width: #{$content-container-max-width + $video-padding};
                padding: 0 $video-padding;
                margin: 0 auto;

                @include ie-only(){
                    flex: auto;
                }
            }
        }

        // Perfect Scrollbar style
        .ps__thumb-y {
            right: 6px;
            width: 4px;
            //background-color: $blue;
        }

        .ps__rail-y {
            right: 5px;
            margin-bottom: 16px;

            &:hover,
            &:focus,
            &.ps--clicking {
                
                .ps__thumb-y {
                    right: 4px;
                    width: 8px;
                }
            }
        }

        @include ie-only(){
            height: 100%;
        }
    }

    &__header {
        padding: rem(32px) rem(25px) rem(20px);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: auto;
       
        #{$filters} &,
        #{$video} & {
            justify-content: flex-end;
        }

        #{$filters} & {
            box-shadow: $bg-shadow;
        }

        #{$lang-switcher} & {
            padding-top: rem(23px);
            padding-right: rem(23px);
            justify-content: flex-end;
        }
        
        @include respond-above(lg) {

            #{$lang-switcher} & {
                padding: rem(24px) rem(36px) rem(20px);
            }

            #{$contact-card} & {
                margin: 0 rem(40px);
                padding-left: 0;
                padding-right: 0;
                border-bottom: 1px solid $gray;
            }

            #{$press} &,
            #{$two-step} & {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                padding: 56px;
            }

            #{$video} & {
                position: absolute;
                top: 0;
                right: #{$video-padding + 8px};
                z-index: 100;
            }
        }
        
        &-title {
            @include t-label-big();
            color: $black;

            #{$filters} & {
                width: 100%;
                padding-left: rem(24px);
                text-align: center;
                font-size: rem(18px);
                line-height: rem(24px);
            }
            
            #{$lang-switcher} & {
                position: absolute;
                width: calc(100% - 60px);
                top: rem(60px);
                left: 0;
                z-index: 1;
                margin: 0 30px;
                text-align: center;
                text-transform: none;
                letter-spacing: 0;
                font-size: rem(24px);
                line-height: rem(28px);
                background-color: $white;

                @include respond-above(lg) {
                    font-size: rem(32px);
                    line-height: rem(40px);
                }
            }

            @include respond-above(lg) {
                #{$press} &,
                #{$two-step} & {
                    display: none;
                }
            }
        }
    }

    &__back {
        @include t-label-big();

        display: none;
        color: $black;
        position: relative;

        &.show {
            display: flex;
        }

        .icon-arrow_left {
            padding: 4px;
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }

        span {
            display: none;

            @include respond-above(lg) {
               color: $blue;
               display: inline-flex;
            }
        }
    }

    &__close {
        color: $black;
        padding: 5px;

       .icon-close {
            font-size: rem(14px);
            display: block;
        }

        #{$video} & {
            .icon-close {
                color: $white;
            }
        }

        @include respond-above(lg) {
            #{$press} &,
            #{$two-step} & {
                margin-left: auto;

                .icon-close {
                    font-size: rem(20px);
                }
            }
        }
    }

    &__body {
        padding-left: rem(25px);
        padding-right: rem(25px);
        overflow-y: auto;
        position: relative;
        padding-bottom: 48px;
        pointer-events: auto;

        #{$filters} & {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            height: 100%;
        }

        #{$lang-switcher} & {
            padding-top: 30px;
        }

        @include respond-above(md) {
            #{$lang-switcher} & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        @include respond-above(lg) {
            padding: rem(48px) rem(40px) rem(40px) rem(40px);

            /*#{$press} &,
            #{$two-step} & {
                //@include custom-scrollbar();
            }*/
            
            #{$press} &,
            #{$two-step} & {
                //overflow-y: visible;
                width: 83.3333333333%;
                padding: 0 9%;
                margin: 0 auto;
            }

            #{$video} & {
                width: 100%;
                padding: 0;
                overflow: hidden;
            }
        }
    }

    &__step {
        display: none;

        &.active {
            display: block;
        }
    }
    
    &__title {
        @include t-h4-title();
        display: none;
        color: $blackGray;

        @include respond-above(lg) {
            display: block;
            line-height: rem(40px);
            letter-spacing: -1.5px;
            margin-bottom: 32px;
        }
    }

    &__image {
        width: 100%;
        height: 238px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 24px;

        picture {
            height: 100%;
            width: 100%;

            img {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: auto;
                width: auto;
            }
        }

        @include respond-above(lg) {
            margin-bottom: 32px;
            width: 369px;
            height: 267px;
        }
    }

    &__video {
        //position: relative;
        padding-top: 56.25%;
        height: 0;
        //background-color: $black;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__text-role {
        @include t-label-small();
        color: $gray;

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: 24px;
        }
    }

    &__text-name {
        color: $black;
        font-size: rem(24px);
        line-height: 30px;
        letter-spacing: -0.5px;
        margin-bottom: 24px;
        
        @include respond-above(lg) {
            font-size: rem(48px);
            line-height: 56px;
            letter-spacing: -1.5px;
        }
    }

    &__text {
        @include t-paragraph-1();
        color: $black;

        #{$press} &,
        #{$two-step} & {
            @include t-paragraph-2();
        }
    }

    &__text-scroll {
        @include respond-above(lg) {
            padding-right: 37px;
            max-height: 480px;
            height:calc(60vh - 100px);
            overflow-y: auto;

            #first-step & {
                height: auto;
                margin-bottom: rem(32px);
                padding-right: 0;

                p {
                    margin-bottom: rem(18px);
    
                    @include respond-above(lg) {
                        margin-bottom: rem(35px);
                    }
                }
            }
        }

        #{$press} &,
        #{$two-step} & {
            p {
                margin-bottom: 38px;
                @include respond-above(lg) {
                    margin-bottom: 44px;
                }
            }
        }
    }

    &__label {
        @include karbonMedium();
        font-size: rem(14px);
        line-height: rem(18px);
        color: $black;
        letter-spacing: 2px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 6px;
    }
    
    &__form {
        padding: 0 28px;
        width: 100%;

        @include respond-above(lg) {
            max-width: 368px;
            margin: 0 auto;
            padding: 0;
        }

        &-select {
            margin-bottom: 20px;

            @include respond-above(lg) {
                margin-bottom: 32px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .custom-select__opener,
        .elem-custom-select select {
            background-color: $white;
            border: 1px solid $black;
            padding: 9px 16px;
            color: $black;
        }

        .custom-select__opener,
        .elem-custom-select {
            &:before {
                color: $black;
                z-index: 1;
            }
        }

        .custom-select__opener {
            border-radius: 8px;
        }

        &-button {
            margin-top: rem(40px);
            text-align: center;
            @include respond-above(lg) {
                margin-top: rem(32px);
                text-align: left;
            }
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__filter-buttons {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 60px 0 70px 0;
    }

    &__reset-filters {
        @include karbonMedium();

        font-size: rem(12px);
        line-height: rem(14px);
        letter-spacing: 2px;
        color: $blue;
        text-decoration: underline;
        text-transform: uppercase;
        margin-bottom: 24px;

        &.hidden {
            display: none;
        }
    }

    &__apply-filters {
        max-width: 270px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: rem(30px);
        padding-bottom: rem(50px);

        .elem-cta {
            width: 100%;
            margin-bottom: rem(20px);
        }

        @include respond-above(md) {

            .elem-cta {
                width: auto;
            }
            
            &.two-columns {
                max-width: 590px;
                margin-right: rem(-16px);
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                .elem-cta {
                    margin-right: rem(16px);
                }
            }
        }
    }

    &__disclaimer {
        margin-top: rem(-10px);
        color: $black;
        font-size: rem(16px);
        line-height: rem(18px);
        text-align: center;
    }

    &__footer {
        pointer-events: auto;

        #{$press} &,
        #{$two-step} & {
            background-color: $white;
            box-shadow: 0 -4px 8px 2px rgba(0,0,0,0.08);
            padding: 24px 48px;
            margin-top: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond-above(lg) {
                display: flex;
                background-color: transparent;
                padding: 0;
                margin-top: 56px;
                box-shadow: none;
                flex-direction: row;
                justify-content: center;
            }
        }

        #first-step.active ~ & {
            display: none;
        }
    }

    &__cta {
        + .widget-modal__cta {
            margin-top: 16px;
        }

        @include respond-above(lg) {
            + .widget-modal__cta {
                margin-left: 56px;
                margin-top: 0;
            }
        }
    }
}