%swiper-pagination-bullet {
    .swiper-pagination-bullet {
        display: inline-block;
        padding: rem(4px);
        margin: 4px;
        width: rem(16px);
        height: rem(16px);
        background: none;
        opacity: 0.5;

        &-active {
            opacity: 1;
        }

        &::after {
            content: '';
            display: block;
            width: rem(8px);
            height: rem(8px);
            background-color: $white;
            border-radius: 50%;
        }
    }
}

%swiper-buttons {
    position: relative;
    width: 32px;
    height: 32px;
    left: auto;
    right: auto;
    border-radius: 50%;
    background-color: rgba(255,255,255,.15);
    
    &:after {
        display: none;
    }

    [class*="icon"] {
        font-size: rem(11px);
        color: $white;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            top: 0;
            left: 0;
        }

        @include respond-above(lg) {
            font-size: rem(16px);

            &:before {
                width: 40px;
                height: 40px;
            }
        }
    }

    @include respond-above(lg) {
        width: 40px;
        height: 40px;
    }
}

.widget-carousel {

    &__container {
        
        @include respond-above(lg) {
            min-height: $carousel-container-height;

            .widget-hero__container {
                min-height: $carousel-container-height;

                #{$wcmmode} & {
                    min-height: 850px !important;
                }
            }

            #{$wcmmode} & {
                min-height: 850px !important;
            }
        }

        &--fixedHeight {
            min-height: unset;
            overflow: hidden;

            .widget-hero__bg-picture {
                height: auto;
        
                @include respond-above(lg) {
                    height: 100%;
                }
            }
        }
    }

    .swiper-pagination {
        bottom: 32px;

        @include respond-above(lg) {
            bottom: 40px;
        }
    }

    @extend %swiper-pagination-bullet;

    .swiper-navigation {
        position: absolute;
        width: 100%;
        bottom: 32px;

        @include respond-above(lg) {
            bottom: 40px;
        }
    }

    .swiper-buttons {
        @include container();
        @include content-container();

        display: flex;
        justify-content: space-between;
    }

    .swiper-button-next,
    .swiper-button-prev {
        @extend %swiper-buttons;

        @include mouse-only() {

            &:hover {
                background-color: $white;

                [class*="icon"] {
                    color: $blue;
                }
            }
        }
    }

    #{$wcmmode} & {

        .swiper {
            position: static !important;
            display: block;
            overflow-y: auto !important;
            height: 100% !important;
        }

        .swiper-wrapper {
            position: static !important;
            display: block;
            height: 100% !important;
            counter-reset: slide;

            .wcm-corp-component.cq-Editable-dom {
                position: relative;
                margin-bottom: 10px;
                border: dashed 2px orangered;
                

                &::before {
                    position: absolute;
                    content: 'Carousel Slide ' counter(slide);
                    top: -2px;
                    left: -2px;
                    padding: 0 10px;
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    background-color: orangered;
                    border: solid 2px orangered;
                    z-index: 999;
                    counter-increment: slide;
                }
            }
        }

        .swiper-slide {
            transform: none !important;
            width: 100% !important;
            opacity: 1 !important;
        }

        .swiper-lazy-preloader {
            display: none;
        }

        .swiper-pagination {
            display: none;
        }
    
        .swiper-navigation {
            display: none;
        }
    }
}