.elem-country {
    width: 100%;
    padding-top: 205px;
    padding-bottom: 40px;

    @include respond-above(lg) {
        padding-bottom: 96px;
        padding-top: 89px;
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__item {
        margin-top: 40px;
        @include respond-above(lg) {
            margin-top: 56px;
        }
    }

    &__title {
        @include karbonRegular();
        font-size: rem(28px);
        line-height: rem(28px);
        letter-spacing: -0.5px;
        color: #323232;
        
        @include respond-above(md) {
            margin-bottom: 16px;
        }

        @include respond-above(lg) {
            font-size: rem(40px);
            line-height: rem(40px);
            letter-spacing: -1px;
        }
    }

    &__content {
        @include respond-above(md) {
            display: flex;
            flex-wrap: wrap;
            margin-left:-8px;
            margin-right: -8px;
        }
    }

    &__contact-item {
        background-color: $white;
        border-radius: 8px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        @include respond-above(md) {
            width: calc(50% - 16px);
            margin-top: 16px;
            margin-left: 8px;
            margin-right: 8px;
        }
        
        @include respond-above(lg) {
            padding: 0;
            flex-direction: row;
            max-width: 561px;
            min-height: 200px;
        }
    }

    &__contact-logo {
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100px;
            height: auto;
        }

        @include respond-above(lg) {
            width: 48%;
            height: 100%;
            margin-bottom: 0;

            img {
                max-width: 138px;
            }
        }
    }

    &__contact-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-above(lg) {
            flex: 0 1 52%;
            padding: 32px 32px 32px 18px;
        }
    }
    
    &__contact-name {
        @include karbonRegular();
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -1px;
        color: $black;

        @include respond-above(lg) {
            font-size: rem(28px);
            letter-spacing: -0.5px;
        }
    }

    &__contact-city {
        @include karbonRegular();
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -0.5px;
        //color: $darkGray;
        color: $gray;
        display: flex;
        margin: 8px 0 6px;

        em {
            padding-left: 5px;
        }
        span {
            margin-left: 13px;
        }

        @include respond-above(lg) {
            
            
        }
    }

    &__contact-link {
        color:$blue;
        display: flex;
        align-items: baseline;
        margin-top: 16px;

        .icon-external-link {
            margin-left: 10px;
        }

        @include respond-above(lg) {
            margin-top: auto;
            padding-top: 16px;
        }
    }

    &__contact-social-items {
        display: flex;
        margin-top: 24px;
    }

    &__contact-social-item {
        display: flex;
        margin-right: 16px;
        
        [class*="icon-"]:before {
            color: $black;
            font-size: rem(32px);
        }

        @include mouse-only() {

            a:hover {
                .icon-linkedin_circle:before {
                    color: #007FC7;
                }

                .icon-facebook_circle:before {
                    color: #4664A3;
                }

                .icon-twitter_circle:before {
                    color: #314351;
                }
            }
        }
    }
}

