.base-main {
    flex: 0 0 auto;

    .no-sticky.base-header ~ &,
    .no-sticky.base-header-lite ~ &
    {
        padding-top: 112px;
        transition: padding-top 0.15s ease;
    }
    .no-sticky.base-header.scrolled ~ &, 
    .no-sticky.base-header-lite.scrolled ~ & {
        padding-top: 72px;
    }

    .no-sticky.base-header.scrolled-hidden ~ &, 
    .no-sticky.base-header-lite.scrolled-hidden ~ & {
        padding-top: 72px;
    }

    .with-stickybar.base-header ~ &, 
    .with-stickybar.base-header-lite ~ & {
        padding-top: rem(110px);
        transition: padding-top .5s ease;
    }

    .with-stickybar.base-header.scrolled-hidden ~ &, 
    .with-stickybar.base-header-lite.scrolled-hidden ~ & {
        padding-top: 80px;
    }

    @include respond-above(lg) {

        .with-stickybar.base-header ~ &, 
        .with-stickybar.base-header-lite ~ & {
            padding-top: rem(72px); }

        .no-sticky.base-header ~ &,
        .no-sticky.base-header-lite ~ &
        {
            padding-top: 72px;
            transition: padding-top 0.15s ease;
        }
    }
}