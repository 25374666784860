.widget-counters {

    &.p-t-def {
        padding-top: rem(40px);

        @include respond-above(lg) {
            padding-top: rem(64px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }


	&__title-main {
		@include content-container();
        @include t-label-big();

        color: $blue;
        font-size: rem(16px);
        line-height: rem(16px);

		.panel-blue & {
            color:$white;
        }

        + .layout-panel__container .layout-panel__column {
            margin-top: 0;
        }
	}

    &__number {
        p {
            font-size: rem(48px);
            line-height: rem(48px);
            color: $blue;
            letter-spacing: -2px;

            @include respond-above(lg) {
                font-size: rem(72px);
                line-height: 72px;
            }

            .panel-blue & {
                color: $white;
            }
        }
    }

    &__title {

        .elem-text--body1 {
            
            @include karbonRegular();
            color: $black;
            text-transform: uppercase;
            font-size: rem(14px);
            letter-spacing: 2px  !important;
            margin-top: 18px;

            .panel-blue & {
                color:$white;
            }
        }
    }

    &__description {
        font-size: rem(14px);
        letter-spacing: 0;
        line-height: 18px;
    }

    &__label {

        .elem-text--body2 {
            color: $black;
            opacity: 0.5;
            text-transform: uppercase;
            font-size: rem(14px);
            letter-spacing: 2px  !important;
            margin-bottom: 16px;

            .panel-blue & {
                color:$white;
            }
        }
    }

    &__footnote {
        
        @include karbonRegular();
        color: $black;
        font-size: 14px;
        letter-spacing: 0;
        line-height: rem(18px);
        margin-top: rem(61px);

        .panel-blue & {
            color: $white;
            opacity: 0.5;
        }

    }
}