@use 'sass:math';

//
//  CONTAINERS
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include container() {}
@mixin container($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: math.div($gutter, 2);
    padding-left: math.div($gutter, 2);
    margin-right: auto;
    margin-left: auto;
}

// @include percentage-container() {}
@mixin percentage-container() {
    width: 100%;
    margin: 0 auto;

    @each $breakpoint, $value in $percentage-container {
        @include respond-above($breakpoint) {
            max-width: #{$value}vw;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

// @include content-container() {}
@mixin content-container() {
    padding-left: $content-container-padding !important;
    padding-right: $content-container-padding !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto;

    @each $breakpoint, $value in $percentage-container {
        @include respond-above($breakpoint) {
            padding-left: #{math.div(100 - $value, 2)}vw !important;
            padding-right: #{math.div(100 - $value, 2)}vw !important;
            max-width: calc( #{$content-container-max-width} + #{ (100 - $value) }vw);
        }
    }
}



//
//  TEXT
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin cut-words() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;	/* Adds a hyphen where the word breaks, if supported */
}

@mixin cut-word-forced() {
    -ms-word-break: break-all;	/*	Old IE	*/
    word-break: break-all;	/*	Firefox	*/
    word-break: break-word;	/*	Chrome / IE	*/
}



//
//  IMAGE SPECIFIC
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin imageCover {
    overflow: hidden;
    height: 100%;
    width: 100%;

    img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;

        #{$edit_mode} & {
            position: static;
            transform: none;
            width: inherit;
        }
    }
}



//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(lg) {}
@mixin respond-above($breakpoint, $orientation: false) {
    // If the breakpoint exists in the map.
    @if map-has-key($grid-breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

        @if ($orientation) {

            @media (min-width: $breakpoint-value) and (orientation: $orientation) {
                @content;
            }

        } @else {

            @media (min-width: $breakpoint-value) {
                @content;
            }
        }

    } @else {

        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}



//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(lg) {}
@mixin respond-below($breakpoint, $orientation: false) {
    // If the breakpoint exists in the map.
    @if map-has-key($grid-breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

        @if ($orientation) {

            @media (max-width: $breakpoint-value - 1) and (orientation: $orientation) {
                @content;
            }

        } @else {

            @media (max-width: $breakpoint-value - 1) {
                @content;
            }
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}



//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(tablet, desktop) {}
@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($grid-breakpoints, $lower);
        $upper-breakpoint: map-get($grid-breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map-has-key($grid-breakpoints, $lower) == false) {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($grid-breakpoints, $upper) == false) {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}



//
//  BROWSER SPECIFIC
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Target css for IE10/11/Edge only
// USAGE: @include ie-only(&){}
@mixin ie-only($isEdge:false) {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        @content;
    }

    @if $isEdge == true {
        @supports (-ms-ime-align:auto) {
            @content;
        }
    }
}


// Target css for Firefox only
// USAGE: @include ff-only(&){}
@mixin ff-only() {
    @supports (-moz-appearance:none) {
        @content;
    }
}

// Target css for Safari only
// USAGE: @include sf-only(&){}
@mixin sf-only() {
    @supports (background: -webkit-named-image(i)) {
        @content;
    }
}




//
//  HARDWARE SPECIFIC
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Exclude device with no hover funcionality (mobile devices)
// USAGE: @include mouse-only(){}

@mixin mouse-only($nomobile: true) {
    
    @if $nomobile == true {
    
        @media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
            @content;
        }
    } @else {

        @media (hover: none) {
            @content;
        }
    }
}


//
//  CUSTOM SCROLLBAR
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin custom-scrollbar {
    /* Works on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--shadowGray) transparent;
    }
    
    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
        height: 160px;
    }
    
    *::-webkit-scrollbar-track {
        background: transparent;
    }
    
    *::-webkit-scrollbar-thumb {
        background-color: var(--shadowGray);
        border-radius: 2px;
        border: 3px solid transparent;
    }
}
