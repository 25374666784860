.elem-brand-list {
    $this-brand: &;
    padding: rem(40px) 0 rem(80px) 0;
    background: $white;

    @include respond-above(lg) {
        padding: rem(80px) 0 rem(120px) 0;
    }

    &__container {
        @include container();
        @include content-container();

        display: grid;
        row-gap: 40px;
        grid-template-columns: 280px;
        justify-content: center;

        @include ie-only() {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include respond-above(lg) {
            grid-template-columns: repeat(3, 280px);
            row-gap: 80px;
            justify-content: space-between;
        }
    }

    &__item {
        width: 100%;
        max-width: 280px;
        height: 280px;
        border: 1px solid transparent;
        border-radius: 16px;
        background:$pageBg ;
        transition: background .3s cubic-bezier(.09,.72,.76,1.01);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        @include ie-only(){
            margin: 20px;
        }

        @include respond-above(lg) {
            @include ie-only(){
                margin: 40px 10px;
            }
        }

        @include mouse-only() {

            &:hover {
                #{$this-brand}__name {
                    @include karbonMedium();
                    color: $white;

                    span {
                        padding-right: 30px;
                    }

                    .icon-arrow_right {
                        opacity: 1;
                        transform: translate(10px,-50%);
                    }
                }

                #{$this-brand}__img {
                    display: none;
                }

                #{$this-brand}__img-hover {
                    display: block;
                }
            }
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
        }
    }

    &__img-hover {
        display: none;
    }

    &__name {
        @include t-paragraph-1();
        color: $black;
        padding-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            position: relative;
            transition: all .2s cubic-bezier(.09,.72,.76,1.01);
        }
        
        .icon-arrow_right {
            transition: all .2s cubic-bezier(.09,.72,.76,1.01);
            transform: translateY(-50%);
            position: absolute;
            top: 49%;
            right: 15px;
            opacity: 0;
        }

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: rem(24px);
        }
    }

    @include mouse-only() {

        .item {
            &--concardis:hover {
                background: $concardis;
            }

            &--ratepay:hover {
                background: $ratepay;
            }

            &--paytrail:hover {
                background: $paytrail;
            }

            &--dankort:hover {
                background: $dankort;
            }

            &--dotpay:hover {
                background:$dotpay;
            }

            &--pep:hover {
                background: $pep;
            }

            &--przelewy:hover {
                background: $przelewy;
            }

            &--poplapay:hover {
                background: $poplapay;
            }

            &--poplatek:hover {
                background: $poplatek;
            }

            &--ccv:hover {
                background: $ccv;
            }

            &--storebox:hover {
                background: $storebox;
            }

            &--signaturGruppen:hover {
                background: $signaturGruppen;
            }

            &--payzone:hover {
                background: $payzone;
            }

            &--edigard:hover {
                background: $edigard;
            }

            &--checkout:hover {
                background: $checkout;
            }

            &--ecard:hover {
                background: $ecard;
            }

            &--sia:hover {
                background: $siapay;
            }
        }
    }
}