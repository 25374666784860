// MODAL
.media-video {
    padding: 70px 0;

    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $black;

        .no-placeholder-image & {
            padding-top: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
        }
    }

    &__play-button {
        position: absolute;
        opacity: 1;
        transition: opacity .3s ease-out, z-index 0s ease-out .3s;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        img {
            width: 48px;
            height: 48px;
            
            @include respond-above(lg) {
                width: 80px;
                height: 80px;
            }
        }

        &.visible-button {
            opacity: 1;
            z-index: 10;
            pointer-events: auto;
        }

        @include ie-only() {
            left: calc(50% - 40px);
            top: calc(50% - 40px);
        }
    }

    &__placeholder-image {
        width: 100%;
        height: auto;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include ie-only() {
        flex-shrink: 0;
    }
}