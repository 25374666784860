.elem-brand-card {

    $this-brand-card: &;

    border-radius: 10px;
    border: 1px solid $white;
    background: rgba(255,255,255,0.16);
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    overflow: hidden;
    cursor: default;

    &.p-t-def {
        padding-top: rem(32px);

        @include respond-above(lg) {
            padding-top: rem(30px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(32px);

        @include respond-above(lg) {
            padding-bottom: rem(30px);
        }
    }

    @include respond-above(lg) {
        justify-content: center;
        height: 280px;
        border: 1px solid transparent;
        padding-left: 10%;
        padding-right: 10%;
        transition: all 0.3s ease-out;

        &:hover {
            border-color: $white;

            #{$this-brand-card}__detail {
                opacity: 1;
                height: 120px;
            }
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-above(lg) {
            justify-content: center;
        }
    }

    &__logo {
        // width: 100%;
        // min-height: 60px;
        max-width: 150px;
        padding: 12px 0 9px 0;

        img {
            width: 100%;
            // max-width: 150px;
            // margin: 0 auto;
        }

        @include respond-above(lg) {
            max-width: 212px;
            padding: 9px 0;
        }
 
    }

    &__detail {
        @include respond-above(lg) {
            width: 100%;
            height: 0;
            opacity: 0;
            transition: all 0.3s ease-out;
        }
    }

    &__description {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -0.5px;
        text-align: center;
        margin-top: rem(16px);

        @include respond-above(lg) {
            margin-top: rem(24px);
        }
        
    }

    .btn-text-link {
        margin-top: rem(24px);
        justify-content: center;

        @include respond-above(lg) {
            margin-top: rem(28px);
        }
    }
}