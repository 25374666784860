$width50: '.column__width--50';
$width33-66: '.column__width--33-66';
$width66-33: '.column__width--66-33';
$width41-58: '.column__width--41-58';
$width58-41: '.column__width--58-41';

$top-left: '.image-top-left';
$top-right: '.image-top-right';
$center-left: '.image-center-left';
$center-right: '.image-center-right';
$bottom-left: '.image-bottom-left';
$bottom-right: '.image-bottom-right';

.layout-wrap-text {
    $this-wrapText: &;
    //padding: rem(24px) 0;

    &__container {
        margin-left: rem(-8px);
        margin-right: rem(-8px);

        @include respond-below(lg) {
            #{$bottom-left} &,
            #{$bottom-right} & {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }


    &__picture {
        shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 100%) content-box;
        shape-margin: 24px;
        float: left;
        // display: block;// try fix safari bug

        #{$bottom-left} &,
        #{$bottom-right} & {
            float: none;
        }

        #{$center-left} &,
        #{$center-right} & {
            float: right;
        }
    }

    &__picture,
    &__slideshow {
        position: relative;
        padding: 0 rem(8px);
        
        #{$top-left} &,
        #{$top-right} & {
            padding-bottom: rem(24px);
        }

        #{$center-right} &,
        #{$center-left} & {
            padding-bottom: rem(24px);
        }

        #{$bottom-left} &,
        #{$bottom-right} & {
            padding-top: rem(24px);

            #{$wcmmode} & {
                margin-top: rem(200px) !important;
            }
        }

        @include respond-above(lg) {
            shape-margin: 44px;
            
            #{$width50} & {
                width: 50%;
            }

            #{$width58-41} & {
                width: 41.6666666667%;
            }

            #{$width41-58} & {
                width: 58.3333333333%;
            }

            #{$width33-66} & {
                width: 66.6666666667%;
            }

            #{$width66-33} & {
                width: 33.3333333333%;
            }

            #{$top-left} &,
            #{$bottom-left} &,
            #{$center-left} & {
                float: left;
                margin-right: 44px;
            }

            #{$top-right} &,
            #{$bottom-right} &,
            #{$center-right} & {
                float: right;
                margin-left: 44px;
            }
            
            #{$center-right} &,
            #{$center-left} & {
                margin-bottom: 44px;
            }

            #{$bottom-left} &,
            #{$bottom-right} & {
                padding-top: 0;
            }

            @include ie-only() {
                #{$center-right} &,
                #{$center-left} & {
                    margin-bottom: 0;
                }
            }
        }

        @include ie-only() {
            margin-top: 0!important;
        }
    }

    &__slideshow {
        @include respond-above(lg) {
            shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 100%) content-box;
            shape-margin: 44px;
            float: left;
        }
    }

    &__text {
        padding: 0 rem(8px);
    }
    
}