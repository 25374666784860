.widget-timeline {
	@include container();
	@include content-container();
	margin-bottom: 16px;

	&__wrapper {
		margin-bottom:56px;
	}
	&__title {
		@include content-container();
		//margin-bottom: 56px;
		margin-bottom: 32px;
	}
	&__container {
		border-radius: 8px;
		background-color: $white;
		width: 100%;
		height: auto;
		display: flex;
		align-items: flex-start;
		padding: 24px;
		flex-direction: column;
		
		@include respond-above(lg) {
			flex-direction: row;
			padding: 36px 56px;
			align-items: center;
		}
	}
	&__year {
		width: 100%;
		color: $blue;
		font-size: rem(60px);
		line-height: rem(63px);
		margin-bottom: rem(16px);
		letter-spacing: -1.5px;

		@include respond-above(lg) {
			width: 25%;
			font-size: rem(100px);
			line-height: rem(106px);
			margin-bottom: 0;
			letter-spacing: -3px;
		}
	}

	&__description {
		//width: 50%;
		//margin: 0 10% 0 10%;
		
		width: 100%;
		margin: 0;

		&--fullWidth {
			width: 100%;
			margin: 0;
		}

		@include respond-above(lg) {
			width:75%;
			padding-left:32px;
		}
	}

	&__description-tag {
		@include t-label-small();
		color: $darkGray;
		margin-bottom: rem(8px);

		@include respond-above(lg) {
			margin-bottom: rem(16px);
		}
	}

	&__description-text {
		color: $black;
		font-size: rem(20px);
		line-height: rem(26px);
		letter-spacing: -0.5px;

		@include respond-above(lg) {
			font-size: rem(28px);
			line-height: rem(34px);
		}
	}
}

