.base-sticky-menu {
    width: 100%;
    background-color: $white;
    color: $black;
    position: fixed;
    left: 0;
    right: 0;
    z-index: setZ(sticky-menu);
    box-shadow:  0 -4px 8px 2px rgba(0,0,0,0.08);
    transition: transform .3s ease-in-out, opacity .3s ease-out;
    display: none;

    @include respond-below(lg) {
        bottom: 0;

        .sticky-menu--mobile & {
            display: block;
        }
    }
    
    @include respond-above(lg) {
        display: block;
        opacity: 0;
        top: 0;
        transition: opacity .3s ease-out, top .3s ease-out;
        box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        pointer-events: none;

        .sticky-scrolled & {
            opacity: 1;
            pointer-events: all;
        }

        .sticky-scrolled .with-stickybar ~ & {
            top: $header-height;
        }

        :not(.scrolled-hidden) ~ & {
            top: $header-height;
        }

        .with-stickybar:not(.scrolled-hidden) ~ & {
            top: 144px;
        }

    }

    &--hidden {
        transform: translateY(100%);
        opacity: 0;
        pointer-events: none;
    }

    &__nav {
        @include percentage-container();
        padding: rem(18px) rem(25px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: $sticky-height-mobile;
        
        @include respond-above(lg) {
            padding-top: rem(9px);
            padding-bottom: rem(8px);
            max-width: 90vw;
            min-height: $sticky-height;
        }
    }

    &__nav-wrapper {
        flex-grow: 1;
        flex-basis: 35%;
        padding-right: 10px;

        @include respond-above(lg) {
            display: flex;
            align-items: center;
            flex-basis: auto;
        }
    }

    &__nav-items {
        display: none;

        @include respond-above(lg) {
            display: flex;
            margin-left: rem(67px);
        }
    }

    &__nav-item {
        @include t-paragraph-2();

        @include respond-above(lg) {
            padding-left: rem(50px);

            &:first-child {
                padding-left: 0;
            }

            &.active a {
                // font-family: KarbonMedium;
                @include karbonMedium();
                color: $blue;
                text-decoration: underline;
            }
        }
    }

    &__nav-link {

        @include mouse-only() {
            &:hover {
                // font-family: KarbonMedium;
                color: $blue;
                text-decoration: underline;
            }
        }

        &:hover {
            @include karbonMedium();
        }
    }

    &__detail {
        @include karbonMedium();
        text-transform: uppercase;
        line-height: 22px;

        @include respond-above(lg) {
            text-transform: none;
            font-size: rem(24px);
            line-height: rem(24px);
            letter-spacing: -0.5px;
        }
    }

    &__left-text .elem-text > p, span {
        margin-bottom: 0;
    }

    &__detail-subtitle {
        @include karbonMedium();
        text-transform: uppercase;
        font-size: rem(12px);
        line-height: rem(22px);
        display: inline;
         
        @include respond-above(lg) {
            display: block;
            margin-top: -4px;
        }
    }
    
    &__info {
        line-height: rem(22px);
        display: flex;
        align-items: center;
        margin-top: -6px;

        @include respond-above(lg) {
            flex-direction: column;
            margin-left: auto;
            margin-right: rem(47px);
            margin-top: 0;
            align-items: flex-start;
            padding-top: rem(3px);
        }
    }

    &__info-text {
        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: 1;
        }
    }

    &__info-price {
        @include karbonMedium();
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -1px;
        padding-left: rem(10px);

        @include respond-above(lg) {
            margin-top: -3px;
            margin-bottom: -3px;
            padding-left: 0;
            letter-spacing: -0.5px;
        }
    }

    &__cta {
        width: auto;
    }

}