.widget-calendar-events {
    $this: &;

    &.p-t-def {
        padding-top: rem(20px);

        @include respond-above(lg) {
            padding-top: rem(30px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(50px);

        @include respond-above(lg) {
            padding-bottom: rem(120px);
        }
    }

    padding-left: 0;
    padding-right: 0;
    color: $black;

    &__container {
        width: 100%;
    }

    &__title {

        @include respond-above(lg) {
            padding: 20px 0;
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 20px;

        @include respond-above(lg) {
            flex-direction: row;
        }
    }

    &__grid,
    &__events-container {
        width: 100%;
        height: 100%;
        opacity: 0;
        
        .init & {
            transition: opacity .5s ease-in;
            opacity: 1;
        }

        @include respond-above(lg) {
            max-width: 50%;
        }
    }

    &__grid {

        @include respond-above(lg) {
            padding: 0 30px;
        }
    }

    &__week-row {
        //position: relative;
        display: flex;
        justify-content: space-around;
        padding-bottom: 10px;

        @include respond-above(md) {
            padding-bottom: 0;
        }
    }

    &__header {
        width: 100%;
        padding: 24px 0;
        justify-content: space-between;
        border-top: 1px solid $lighterGray;
        border-bottom: 1px solid $lighterGray;

        @include respond-above(lg) {
            padding: 34px 0;
            border-top: none;
        }
    }

    &__month-title {
        font-size: rem(18px);
        text-align: center;

        @include respond-above(lg) {
            font-size: rem(24px);
        }
    }

    &__prev-month, &__next-month {
        display: flex;
        align-items: center;
        margin: -10px -10px;
        padding: 10px;
        border: none;
        
        em {
            font-size: rem(16px);

            @include respond-above(lg) {
                font-size: rem(18px);
            }
        }

        &[disabled] {
            display: block;
            opacity: 0;
            pointer-events: none;
            cursor: default;
        }

        @include mouse-only() {
            &:hover {
                cursor: pointer;
                color: $blue;
            }
        }
    }

    &__prev-month {
        padding-right: 15px;
    }
    
    &__next-month {
        padding-left: 15px;
    }

    &__sub-header {
        width: 100%;
        padding: 30px 0 20px 0;

        @include respond-above(lg) {
            padding: 40px 0 30px 0;
        }
    }

    &__day-header {
        text-align: center;
    }
    
    &__day-header-cell {
        @include karbonMedium();

        font-size: rem(18px);
        color: $darkerGray;
        text-align: center;
        //padding: 3px;
        -webkit-touch-callout: none; /* iOS Safari */
        user-select: none;
        text-transform: capitalize;
    }
    
    &__day-col {
        display: flex;
        position: relative;
        //width: calc(100% / 7);
        width: 100%;
        padding: 0 0 calc(100% / 7 - 4px) 0;
        margin: 2px 2px;
        //padding: 0 0 calc(100% / 7) 0; // = width for a 1:1 aspect ratio
        //margin: 0;
        //overflow: hidden;
        text-align: center;

        &:first-child {
            
            #{$this}__day-multisel {
                left: 0;
                width: 100%;
                max-width: 80px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                @include respond-above(lg) {
                    max-width: 62px;
                }
            }

            .selected + #{$this}__day-multisel {
                display: none;
            }
        }

        &:last-child {
            
            #{$this}__day-multisel {
                left: 0;
                width: 100%;
                max-width: 80px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;

                @include respond-above(lg) {
                    max-width: 62px;
                }
            }

            .selected + #{$this}__day-multisel {
                display: none;
            }
        }

        @include respond-above(md) {
            padding: 0 0 calc(100% / 7) 0; // = width for a 1:1 aspect ratio
            margin: 2px 0;
        }
    }
    
    &__day-cell {
        @include karbonMedium();

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        max-width: 80px;
        max-height: 80px;
        padding: 0;
        margin: auto;
        border-radius: 4px;
        cursor: pointer;
        letter-spacing: 2px;
        -webkit-touch-callout: none; /* iOS Safari */
        user-select: none;
        z-index: 1;

        &.today {
            border: 1px solid $blue;

            span {
                color: $blue;
            }
        }

        &.selected {
            background-color: $blue;

            span {
                color: $white;
            }
        }

        @include respond-above(lg) {
            max-width: 62px;
            max-height: 62px;
            padding: 0;
        }

        @include mouse-only() {

            &:not(.selected):not(.multisel-day):hover {
                background-color: $calendarGray;

                span {
                    color: $black;
                }
            }
        }
    }

    &__day-multisel {
        position: absolute;
        display: none;
        top: 0;
        left: -20%;
        bottom: 0;
        right: 0;
        max-height: 80px;
        height: 100%;
        width: 140%;
        padding: 0;
        margin: auto;
        background-color: #EAEAF6;
        z-index: 0;

        .multisel-day + & {
            display: flex;
        }

        .start-day + & {
            left: 50%;
            width: 100%;
        }

        .end-day + & {
            left: -50%;
            width: 100%;
        }

        @include respond-above(lg) {
            max-height: 62px;
            padding: 0;
        }
    }

    &__day-categories {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 34%;
        padding-bottom: 12%;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;

        li {
            display: none;
            background-color: $gray;
            height: 2px;
            width: 60%;
            margin-top: 2px;
            font-size: 0;

            &:first-child,
            &:nth-child(2) {
                display: block; // Show just the first two categories
            }

            .selected & {
                background-color: var(--bg-alt-color, white) !important;
            }

            @include respond-above(md) {
                height: 4px;
                margin-top: 4px;
                width: 52%;
            }

            @include respond-above(lg) {
                margin-top: 2px;
            }
        }

        @include respond-above(md) {
            padding-bottom: 14%;
        }

        @include respond-above(lg) {
            height: 36%;
        }
    }

    &__date-label {
        font-size: rem(18px);
        color: $black;
        pointer-events: none;
    }

    &__events-container {
        width: calc(100% + 48px);
        height: 100%;
        background-color: $calendarEventsListBg;
        margin-left: -24px;
        //max-height: $calendar-events-list-max-height;
        //overflow: hidden;

        @include respond-above(lg) {
            width: 100%;
            margin-left: 0;
            border-radius: 8px;
        }
    }

    &__events-title {
        padding: 30px 20px 20px;

        > * {
            font-size: rem(24px);
            line-height: rem(31px);
            letter-spacing: -1px;

            @include respond-above(lg) {
                font-size: rem(28px);
                line-height: rem(34px);
            }
        }

        .future-click-title,
        .past-click-title {
            display: none;
        }

        &.future {

            .future-click-title {
                display: block;
            }
        }

        &.past {

            .past-click-title {
                display: block;
            }
        }

        &.future,
        &.past {

            .default-title {
                display: none;
            }
        }

        @include respond-above(lg) {
            padding: 30px 20px;
        }
    }

    &__events-list {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 20px;

        // Perfect Scrollbar style
        .ps__thumb-y {
            right: 6px;
            width: 4px;
        }

        .ps__rail-y {
            margin-bottom: 16px;

            &:hover,
            &:focus,
            &.ps--clicking {

                .ps__thumb-y {
                    right: 4px;
                    width: 8px;
                }
            }
        }

        @include respond-above(lg) {
            padding: 0 16px;
            //overflow-y: auto;
            max-height: $calendar-events-list-max-height;
        }
    }

    &__events-message {
        display: none;
        text-align: center;

        .events-message {
            display: none;
            font-size: rem(24px);
            line-height: rem(32px);
        }

        &.no-events,
        &.unscheduled {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 215px;
            padding: 0 25px;

            @include respond-above(lg) {
                min-height: 520px;
            }
        }

        &.no-events {
            .no-events {
                display: inline-block;
            }
        }

        &.unscheduled {
            .unscheduled {
                display: inline-block;
            }
        }
    }

    &__legend {
        width: 100%;
        padding: 0 0 40px;
        opacity: 0;

        .init & {
            transition: opacity .5s ease-in;
            opacity: 1;
        }

        @include respond-above(lg) {
            order: 3;
            padding: 20px 0;
        }
    }

    &__legend-category {
        @include karbonMedium();

        display: flex;
        align-items: center;
        font-size: rem(14px);
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 6px 0;

        em {
            @extend %category-bullet;
        }

        @include respond-above(lg) {
            font-size: rem(16px);
        }
    }

    .hidden {
        display: none !important;
    }
}
