
.elem-slideshow {
    @extend %swiper-pagination-bullet;

    width: 100%;
    overflow: hidden;

    .swiper-pagination {
        height: 16px;
        bottom: 4px;

        @include respond-above(lg) {
            height: auto;
            bottom: 20px;
        }
    }

    .swiper-pagination-bullet {
        @include respond-below(lg) {
            &::after {
                background-color: $black;
            }
        }
    }

    .swiper-navigation {
        margin-top: rem(8px);

        @include respond-above(lg) {
            position: absolute;
            width: 100%;
            top: 50%;
            margin-top:-20px;
        }
    }

    .swiper-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        @include respond-above(lg) {
            padding: 0 rem(24px);
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        @extend %swiper-buttons;
        
        @include respond-below(lg) {
            width: 24px;
            height: 24px;
            background-color: rgba(0,0,0,.5);

            [class*="icon"] {
                font-size: rem(8px);

                &:before {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        @include respond-above(lg) {
            background-color: rgba(255,255,255,.8);
    
            [class*="icon"] {
                color: $blue;
            }

            @include mouse-only() {
                &:hover {
                    background-color:$white;
                }
            }
        }
    }

    .swiper-slide {
        border-radius: 11px;
        overflow: hidden;
    }
}