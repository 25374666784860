$fontsFolder: './resources/fonts';

@font-face {
    font-family: "KarbonRegular";
    font-style: normal;
    src: url('#{$fontsFolder}/karbon/KarbonApp.woff2') format('woff2'),
         url('#{$fontsFolder}/karbon/KarbonApp.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: "KarbonMedium";
    font-style: normal;
    src: url('#{$fontsFolder}/karbon/KarbonAppMedium.woff2') format('woff2'),
         url('#{$fontsFolder}/karbon/KarbonAppMedium.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: "KarbonLight";
    font-style: normal;
    src: url('#{$fontsFolder}/karbon/KarbonAppLight.woff2') format('woff2'),
         url('#{$fontsFolder}/karbon/KarbonAppLight.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: "KarbonSemibold";
    font-style: normal;
    src: url('#{$fontsFolder}/karbon/KarbonAppSemibold.woff2') format('woff2'),
         url('#{$fontsFolder}/karbon/KarbonAppSemibold.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: "KarbonBold";
    font-style: normal;
    src: url('#{$fontsFolder}/karbon/KarbonAppBold.woff2') format('woff2'),
         url('#{$fontsFolder}/karbon/KarbonAppBold.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: "NexiCondensedSemibold";
    font-style: normal;
    src: url('#{$fontsFolder}/nexi-condensed/NexiCondensedSemibold.woff2') format('woff2'),
         url('#{$fontsFolder}/nexi-condensed/NexiCondensedSemibold.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: 'NexiNumberMono';
    font-style: normal;
    src: url('#{$fontsFolder}/nexi-number/nexi_num_mono_1.2-webfont.woff2') format('woff2'),
         url('#{$fontsFolder}/nexi-number/nexi_num_mono_1.2-webfont.woff') format('woff');
    font-display: auto;
}

@font-face {
    font-family: 'NexiNumberMonoSemibold';
    font-style: normal;
    src: url('#{$fontsFolder}/nexi-number/nexi_num_mono-semibold_1.2-webfont.woff2') format('woff2'),
         url('#{$fontsFolder}/nexi-number/nexi_num_mono-semibold_1.2-webfont.woff') format('woff');
    font-display: auto;
    font-weight: 600;
}

@font-face {
    font-family: 'NexiNumberMonoThin';
    font-style: normal;
    src: url('#{$fontsFolder}/nexi-number/nexi_num_mono-thin_1.2-webfont.woff2') format('woff2'),
         url('#{$fontsFolder}/nexi-number/nexi_num_mono-thin_1.2-webfont.woff') format('woff');
    font-display: auto;
    font-weight: 200;
}

@font-face {
    font-family: 'UbuntuThin';
    src: url('#{$fontsFolder}/ubuntu/Ubuntu-Thin.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/Ubuntu-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuRegular';
    src: url('#{$fontsFolder}/ubuntu/Ubuntu.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/Ubuntu.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuMedium';
    src: url('#{$fontsFolder}/ubuntu/Ubuntu-Medium.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuLight';
    src: url('#{$fontsFolder}/ubuntu/Ubuntu-Light.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuBold';
    src: url('#{$fontsFolder}/ubuntu/Ubuntu-Bold.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuMonoRegular';
    src: url('#{$fontsFolder}/ubuntu/UbuntuMono-Regular.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/UbuntuMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuMonoBold';
    src: url('#{$fontsFolder}/ubuntu/UbuntuMono-Bold.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/UbuntuMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}

@font-face {
    font-family: 'UbuntuCondensed';
    src: url('#{$fontsFolder}/ubuntu/UbuntuCondensed-Regular.woff2') format('woff2'),
        url('#{$fontsFolder}/ubuntu/UbuntuCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    size-adjust: 85%;
}


// NEXI SANS
@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-Thin.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-Thin.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-ThinItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-ThinItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-Light.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-LightItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-Regular.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-RegularItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-Medium.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-MediumItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-SemiBold.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-SemiBold.woff2') format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-SemiBoldItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}



@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-Bold.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-Bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'NexiSans';
    src: url('#{$fontsFolder}/nexisans/NexiSans-BoldItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans/NexiSans-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

// NEXI SANS PRO
@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Thin.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Thin.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 200;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-ThinItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-ThinItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 200;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Light.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Light.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 300;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-LightItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-LightItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 300;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Regular.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Regular.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 400;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-RegularItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-RegularItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 400;
}


@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Medium.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Medium.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 500;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-MediumItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-MediumItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 500;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-SemiBold.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-SemiBold.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 600;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-SemiBoldItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-SemiBoldItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 600;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Bold.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-Bold.woff2') format('woff2');
    font-style: normal;
    font-display: fallback;
    font-weight: 700;
}

@font-face {
    font-family: 'NexiSansPro';
    src: url('#{$fontsFolder}/nexisans-pro/NexiSansPro-BoldItalic.woff') format('woff'),
         url('#{$fontsFolder}/nexisans-pro/NexiSansPro-BoldItalic.woff2') format('woff2');
    font-style: italic;
    font-display: fallback;
    font-weight: 700;
}




