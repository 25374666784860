.widget-hero {
    $this: &;
    position: relative;
    width: 100%;
    z-index: 1; 

    &#{$this}--country {
        z-index: auto; // to view select country over Widget Highlight
        background-color: $bgHeroCountry;
    }

    &#{$this}--news {
        background-color: $white;
        color: $black;
    }

    &#{$this}--event {
        background-color: $pageBg;
    }

    &__bg-picture {
        @include imageCover;
        
        position: absolute;
        width: calc(100vw + 2px);   // Workaround for the side white pixel
        max-width: calc(100vw + 2px);   // Workaround for the side white pixel
        margin-left: -1px;   // Workaround for the side white pixel

        &.media-picture--lazy::after {
            display: none;  // lazy loading animation disabled
        }

        #{$this}--country & {
            img {
                width: 100%;
                height: auto;
                min-height: auto;
            }
        }


        @include respond-above(lg) {
            #{$this}--country & {
                display: none;
            }
        }

        #{$edit_mode} & {
            img {
                position: relative !important;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) !important;
                width: auto !important;
            }
        }
    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.2));
        pointer-events: none;
    }

    &__container {
        @include container();
        @include content-container();

        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 200;

        #{$this}--brand & {
            flex-direction: row;    // SVG logo fix
        }
       
        #{$this}--small & {
            min-height: $hero-height-small;
        }

        #{$this}--medium & {
            min-height: $hero-height-medium-mobile;
        }

        #{$this}--medium#{$this}--event & {
            min-height: $hero-height-small;
        }

        #{$this}--large & {
            min-height: $hero-height-large;

            #{$wcmmode} & {
                min-height: 666px !important;
            }
        }

        #{$this}--full & {
            min-height: $hero-height-full;

            #{$wcmmode} & {
                min-height: 850px !important;
            }
        }

        #{$this}--highlight & {
           min-height: $hero-height-highlight;
        }

        #{$this}--highlight:last-child & {
            min-height: $hero-height-medium-mobile;
        }

        @include respond-below(md) {
            #{$this}--country#{$this}--full & {
                min-height: 510px;
            }
        }

        @include respond-above(lg) {
            flex-direction: row;
            z-index: 200;

            #{$this}--medium & {
                min-height: $hero-height-medium-desktop;
            }

            #{$this}--highlight & {
                min-height: 720px;
            }

            #{$this}--highlight:last-child & {
                min-height: $hero-height-medium-desktop;
            }

            #{$this}--full-footer & {
                min-height: $hero-height-full-footer;

                #{$wcmmode} & {
                    min-height: 850px !important;
                }
            }

            #{$wcmmode} #{$this}--country & { 
                flex-direction: column;
            }
 
        }
    }

    &__picture {
        padding-top: rem(80px);
        width: 100%;
        
        picture {
            border-radius: 8px;
        }

        @include respond-above(lg) {
            @include make-col(5);

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: rem(176px) 0 rem(92px) 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        //width: 100%;
        padding: rem(160px) 0 rem(90px) 0;

        #{$this}--highlight & {
            padding-bottom: rem(170px);
        }

        #{$this}--brand & {
            padding-top: rem(115px);
        }

        #{$this}__picture + & {
            margin-top: 15px;
            padding-top: 0;
        }

        #{$this}--news & {
            padding-bottom: rem(41px);
        }

        #{$this}--country & {
            z-index: 10;
            pointer-events: none;
            padding-top: rem(111px);
            padding-bottom: rem(40px);
            margin-bottom: rem(140px);

            #{$wcmmode} & {
                z-index: 11;
            }
        }

        #{$this}--event & {
            border-top: 1px solid $lightGray;
            padding: rem(40px) 0;
            margin-top: rem(160px);
        }

        @include respond-above(lg) {
            @include make-col(10);

            padding: rem(176px) 0 rem(72px) 0;

            #{$this}--brand & {
                @include make-col(12);

                padding-top: rem(230px);
            }

            #{$this}--highlight & {
                padding-bottom: rem(240px);
            }

            #{$this}__picture + & {
                @include make-col(6);
                @include make-col-offset(1);

                margin-top: 0;
                padding-top: rem(176px);
                justify-content: center;
            }

            #{$this}--news & {
                padding-top: rem(208px);
                padding-bottom: rem(48px);
            }

            #{$this}--country & {
                @include make-col(6);
                padding: rem(176px) 0 0 0;
                margin-bottom: rem(339px);
                position: relative;
            }

            #{$this}--event & {
                @include make-col(12);
                padding-top: rem(56px);
                padding-bottom: rem(56px);
                margin-top: rem(176px);
            }
        }

        @include respond-above(xl) {
            #{$this}--country & {
                @include make-col(5);
            }
        }
    }

    &__pre-title {
        margin-bottom: rem(16px);

        h1, h2, h3, h4, h5, h6, p, span {
            @include t-label-big();
            
            line-height: 20px;
            margin: 0;
            padding: 0 3px;

            #{$this}--brand & {
                padding: 0;
            }

            @include respond-above(lg) {
                padding: 0 6px;
            }
        }

        @include respond-above(lg) {
            margin-bottom: rem(20px);
        }

        
        #{$this}--news & {
            line-height: 14px;

            @include respond-above(lg) {
                line-height: 24px;
                margin-bottom: rem(24px);
            }
        }

        #{$this}--event & {
            color: $black;

            span {
                padding: 0;
            }
        }
    }

    &__hours {
        position: relative;
        display: inline-block;
        padding-left: 20px;
        margin-bottom: 8px;
        white-space: nowrap;
        color: $darkGray;

        em {
            position: absolute;
            top: 0;
            left: 0;
            font-size: rem(12px);
            color: #C0C0C0;

            @include respond-above(lg) {
                font-size: rem(17px);
            }
        }
    }

    &__title {

        > * {
            @include t-h1-title();

            //color: $white;
            margin: 0;
            padding: 0;
        }

        &#{$this}--small-text > * {
            @include t-h4-title();

            @include respond-above(lg) {
                @include t-h3-title();
            }
        }
        #{$this}--event &,
        #{$this}--news & {
            > * {
                color: $black;
            }

            &#{$this}--small-text > * {
                @include t-h3-title();
            }
        }

        #{$this}--event & {
            
            > * {
                font-size: rem(32px);
                letter-spacing: -1px;
                line-height: rem(40px);
                word-wrap:break-word;

                @include respond-above(lg) {
                    font-size: rem(64px);
                    line-height: rem(72px);
                    letter-spacing: -2.5px;
                }
            }

            @include respond-above(lg) {
                max-width:80%;
            }
        }


        + #{$this}__subtitle {
            margin-top: rem(16px);
            
            @include respond-above(lg) {
                margin-top: rem(24px);
            }
        }

    }

    &__logo {
        max-height: 190px;
        padding: 10px 0 40px;
        text-align: left;

        img {
            max-width: 100%;
            max-height: 100%;
            height: auto;

            &[src$=".svg"] {
                width: auto;
                max-width: 50%;
                height: 100%;
                //max-height: 80px;

                @include respond-above(lg) {
                    max-width: 35%;
                    //max-height: 140px;
                    //height: 140px;

                    @include ie-only() {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    &__description {
        @include t-h5-title();

        margin-top: rem(16px);

        &.hidden {
            display: none;
        }

        @include respond-above(lg) {
            letter-spacing: 0;

            &.hidden {
                display: block;
            }
        }

        #{$this}--brand &,
        #{$this}--country & {
            @include t-paragraph-1();
        }

        #{$this}--country & {
            @include respond-above(lg) {
                margin-top: rem(45px);
            }
        }

        &#{$this}--two-columns {

            @include respond-above(lg) {
                column-count: 2;
                column-gap: #{$grid-gutter-width * 2};
            }
        }

        #{$this}--event & {
            font-size: rem(16px);
            line-height: rem(22px);
            color: $darkGray;

            @include respond-above(lg) {
                font-size: rem(24px);
                letter-spacing: -0.5px;
                line-height: rem(32px);
                margin-top: rem(32px);
            }
        }
    }

    &__subtitle {
        @include t-h5-title();
        
        margin-top: rem(32px);

        #{$this}--event & {
            font-size: rem(16px);
            line-height: rem(22px);
            color: $darkGray;
            
            @include respond-above(lg) {
                font-size: rem(24px);
                letter-spacing: -0.5px;
                line-height: 32px;
            }

            &-label {
                @include respond-above(lg) {
                    color: $black;
                }
            }
        }
    }

    &__select {
        margin-top: 30px;
        margin-bottom: 26px;

        #{$this}--large & {
            margin-bottom: 0;
        }

        @include respond-above(lg) {
            margin-top: rem(80px);
            margin-bottom: rem(106px);

            .ps__rail-y {
                margin-right: 11px;
            }
        }
    }

    &__link {
        @include t-paragraph-1();

        display: inline-block;
        margin-top: 32px;

        span {
            text-decoration: underline;
        }

        [class*="icon"] {
            margin-left: 5px;

            @include respond-above(lg) {
                font-size: rem(20px);
            }
        }
    }

    &__cta-container {
        margin-top: 32px;

        @include respond-above(lg) {
            margin-top: 50px;
        }
    }

    &__cta {
        + #{$this}__cta {
            margin-top: 16px;
        }

        @include respond-above(lg) {
            + #{$this}__cta {
                margin-top: 0;
                margin-left: 16px;
            }
        }
    }

    &__footer-container {
        display: flex;
        flex-direction: column;
        margin-top: rem(16px);

        @include respond-above(lg) {
            margin-top: rem(25px);
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__info {
        font-size: rem(16px);
        line-height: rem(22px);
        
        @include respond-above(lg) {
            color: $blue;
            font-size: rem(18px);
            line-height: rem(24px);
            letter-spacing: -0.5px;
            display: flex;

            span {
                line-height: rem(32px);
            }
            
            span:first-child {
                padding-right: rem(15px);
            }
        }
    }

    &__author {
        ~ #{$this}__time {
            @include respond-above(lg) {
                border-left: 1px solid #D7D7D7;
                padding-left: rem(15px);
            }
        }
    }

    &__line {
        @include respond-above(lg) {
            display: none;
        }
    }

    + .widget-highlight {
        .widget-highlight__container {
            margin-top: rem(-145px);

            @include respond-above(lg) {
                margin-top: rem(-234px);
            }
        }

        &.widget-highlight--entity {
            .widget-highlight__container {
                margin-top: rem(-75px);

                @include respond-above(lg) {
                    margin-top: rem(-89px);
                }
            }
        }

        .widget-highlight__content {
            box-shadow: 0 3px 16px 0 rgba(0,0,0,0.16);

            @include respond-above(lg) {
                margin-bottom: 66px;
            }
        }
        
        .widget-highlight__bottom {
            display: none;
        }

        .widget-highlight__label,
        .widget-highlight__link {
            color: $white;
        }

        .elem-card__content {
            min-height: 314px;
            
            @include respond-above(lg) {
                min-height: 302px;
            }
        }
    }

    .base-main__item ~ .base-main__item & {
        margin-top: -160px;

        @include respond-above(lg) {
            margin-top: -176px;
        }
    }

    &__map {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: auto;
        //display: none;

        .svgMap-container,
        .svgMap-map-container,
        .svgMap-map-wrapper {
            width: 100%;
            height: 100%;
        }

        .svgMap-map-image {
           

            @include respond-above(lg) {
                width: 100vw;
                height: 100vh;
            }
        }

        @include respond-above(lg) {
            display: block;
        }
    }
}