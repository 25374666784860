$nexi-iconfont-family: 'nexicorp' !default;
$nexi-iconfont-path: './resources/fonts/nexicorp' !default;

@font-face {
    font-family: '#{$nexi-iconfont-family}';
    src: url('#{$nexi-iconfont-path}/#{$nexi-iconfont-family}.ttf?5goiff') format('truetype'),
        url('#{$nexi-iconfont-path}/#{$nexi-iconfont-family}.woff?5goiff') format('woff'),
        url('#{$nexi-iconfont-path}/#{$nexi-iconfont-family}.svg?5goiff##{$nexi-iconfont-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class*='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$nexi-iconfont-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: rem(24px); // Default icon grid size

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*&:before {
        text-transform: uppercase !important;   // IE11 fix
    }*/

    ///// CUSTOM GRID CLASSES
    ///// Use one of this classes to correctly size an icon
    &.grid16 {
        font-size: rem(16px);
    }
    &.grid32 {
        font-size: rem(32px);
    }
    //////////////////////////
}

$icon-burger: "\e901";
$icon-close: "\e900";
$icon-check: "\e923";
$icon-caret_up: "\e905";
$icon-caret_down: "\e91d";
$icon-caret_right: "\e904";
$icon-caret_right_alt: "\e91e";
$icon-arrow_right: "\e902";
$icon-external-link: "\e90c";
$icon-phone: "\e921";
$icon-email: "\e90d";
$icon-home: "\e90f";
$icon-download: "\e910";
$icon-calendar: "\e915";
$icon-clock: "\e903";
$icon-share: "\e907";
$icon-share-alt: "\e92f";
$icon-plus: "\e918";
$icon-minus: "\e919";
$icon-user: "\e926";
$icon-filter: "\e924";
$icon-arrow_up_alt: "\e91f";
$icon-arrow_up: "\e90e";
$icon-globe_closed: "\e906";
$icon-outline_pin: "\e908";
$icon-copy: "\e91a";
$icon-twitter: "\e91b";
$icon-linkedin: "\e91c";
$icon-facebook: "\e922";
$icon-xing: "\e93b";
$icon-xing_circle: "\e920";
$icon-facebook_circle: "\e909";
$icon-twitter_circle: "\e90a";
$icon-linkedin_circle: "\e90b";
$icon-instagram_circle: "\e927";
$icon-email_circle: "\e911";
$icon-youtube_circle: "\e916";
$icon-alert: "\e92c";
$icon-book: "\e912";
$icon-microphone: "\e913";
$icon-pdf: "\e914";
$icon-letter: "\e917";
$icon-play: "\e925";
$icon-close_circle: "\e937";
$icon-check_circle: "\e936";

.icon-close {
    font-size: rem(16px);
    &:before {
        content: $icon-close;
    }
}
.icon-check {
    font-size: rem(16px);
    &:before {
        content: $icon-check;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-burger {
    font-size: rem(16px);
    &:before {
        content: $icon-burger;
    }
}
.icon-arrow_right {
    font-size: rem(16px);
    &:before {
        content: $icon-arrow_right;
    }
}
.icon-arrow_left {
    font-size: rem(16px);
    transform: scaleX(-1);
    &:before {
        content: $icon-arrow_right;
    }
}
.icon-globe_closed {
    &:before {
        content: $icon-globe_closed;
    }
}
.icon-caret_right {
    font-size: rem(16px);
    &:before {
        content: $icon-caret_right;
    }
}
.icon-caret_up {
    font-size: rem(16px);
    &:before {
        content: $icon-caret_up;
    }
}
.icon-caret_down {
    font-size: rem(16px);
    &:before {
        content: $icon-caret_down;
    }
}
.icon-caret_right {
    font-size: rem(16px);
    &:before {
        content: $icon-caret_right;
    }
}
.icon-caret_right_alt {
    font-size: rem(16px);
    &:before {
        content: $icon-caret_right_alt;
    }
}
.icon-outline_pin {
    &:before {
        content: $icon-outline_pin;
    }
}
.icon-external-link {
    font-size: rem(16px);
    &:before {
        content: $icon-external-link;
    }
}
.icon-phone {
    font-size: rem(16px);
    &:before {
        content: $icon-phone;
    }
}
.icon-email {
    font-size: rem(16px);
    &:before {
        content: $icon-email;
    }
}
.icon-home {
    font-size: rem(16px);
    &:before {
        color: #fff;
        content: $icon-home;
    }
}
.icon-download {
    font-size: rem(16px);
    &:before {
        content: $icon-download;
    }
}
.icon-arrow_up {
    &:before {
        content: $icon-arrow_up;
    }
}
.icon-arrow_up_alt {
    &:before {
        content: $icon-arrow_up_alt;
    }
}
.icon-xing {
    &:before {
        content: $icon-xing;
    }
}
.icon-xing_circle {
    &:before {
        color: #fff;
        content: $icon-xing_circle;
    }
}
.icon-twitter_circle {
    &:before {
        color: #fff;
        content: $icon-twitter_circle;
    }
}
.icon-facebook_circle {
    &:before {
        color: #fff;
        content: $icon-facebook_circle;
    }
}
.icon-linkedin_circle {
    &:before {
        color: #fff;
        content: $icon-linkedin_circle;
    }
}
.icon-instagram_circle {
    &:before {
        color: #fff;
        content: $icon-instagram_circle;
    }
}
.icon-email_circle {
    &:before {
        content: $icon-email_circle;
    }
}
.icon-youtube_circle {
    &:before {
        content: $icon-youtube_circle;
    }
}
.icon-share {
    font-size: rem(16px);
    &:before {
        color: #fff;
        content: $icon-share;
    }
}
.icon-share-alt {
    &:before {
        color: #fff;
        content: $icon-share-alt;
    }
}
.icon-calendar {
    font-size: rem(12px);
    &:before {
        content: $icon-calendar;
    }
}
.icon-clock {
    font-size: rem(16px);
    &:before {
        content: $icon-clock;
    }
}
.icon-book {
    font-size: rem(32px);
    &:before {
        content: $icon-book;
    }
}
.icon-microphone {
    font-size: rem(32px);
    &:before {
        content: $icon-microphone;
    }
}
.icon-pdf {
    font-size: rem(32px);
    &:before {
        content: $icon-pdf;
    }
}
.icon-letter {
    font-size: rem(32px);
    &:before {
        content: $icon-letter;
    }
}
.icon-copy {
    &:before {
        content: $icon-copy;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter_circle;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook; 
    }
}
.icon-play {
    font-size: rem(32px);
    &:before {
        content: $icon-play;
    }
}
.icon-plus {
    font-size: rem(16px);
    &:before {
        color: $blue;
        content: $icon-plus;
    }
}
.icon-minus {
    font-size: rem(16px);
    &:before {
        color: $blue;
        content: $icon-minus;
    }
}
.icon-user {
    font-size: rem(16px);
    &:before {
        content: $icon-user;
    }
}
.icon-alert {
    &:before {
        content: $icon-alert;
    }
}
.icon-close_circle {
    font-size: rem(32px);
    &:before {
        content: $icon-close_circle;
    }
}
.icon-check_circle {
    font-size: rem(32px);
    &:before {
        content: $icon-check_circle;
    }
}