.elem-squared-card {
    
    &__container {
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        height: 250px;

        &.elem-squared-card__overlay {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: $black;
                opacity: 0.52;
                z-index: 1;
            }
        }
    }

    &__picture {
        @include imageCover;

        position:absolute;
        z-index: 0;
    }

    &__content {
        position: relative;
        z-index: 2;
        padding: rem(16px);
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__title,
    &__text {
        color: $white;
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -0.5px;
    }

    &__title {
        @include karbonMedium();

        display: -webkit-box;   /* Ellipsis hack */
        -webkit-line-clamp: 2;  /* Ellipsis hack */
        -webkit-box-orient: vertical;   /* Ellipsis hack */
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        margin-bottom: rem(10px);
        max-height: calc(#{rem(24px)} * 2);
    }

    &__cta {
        margin-top: auto;

        @include respond-below(lg) {
            a {
                display: block;
                margin: 0 auto;
            }
        }
    }
}