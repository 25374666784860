.elem-unordered-list {

    $this-list: &;
    
    &__title {
        @include  t-label-big();
        color: $black;
        margin-bottom: 16px;

        + #{$this-list}__list {
            #{$this-list}__item {
                &:first-child {
                    border-top: 0;
                }
            }
        }

        @include respond-above(lg) {
            margin-bottom: 32px;
        }
    }

    &__item {
        @include t-h5-title();
        
        border-top: 1px solid $lightGray;
        padding: 16px 0;
        color: $black;

        &:last-child {
            padding-bottom: 0;
        }

        .panel-blue & {
            color: $white;
        }
    }

    &--big {
        #{$this-list}__list {
            @include respond-above(lg) {
                width: 50%;
            }
        }

        #{$this-list}__item {
            @include t-h4-title();

            color: $darkerGray;
            padding: 24px 0 20px 0;

            @include respond-above(lg) {
                padding: 32px 0;
                letter-spacing: -1.5px;
            }
        }
    }

    .base-main__item > & {
        padding-bottom: 40px;
   
        @include respond-above(lg) {
            padding-bottom: 96px;
        }
        
        .elem-unordered-list__container {
            @include container();
            @include content-container();
        }
    }
}