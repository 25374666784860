.elem-entity-highlight {
    @include container();
    @include content-container();
     
    position: absolute;
    left: 0;
    bottom: -196px;
    
    @include respond-above(lg) {
        bottom: -89px;
    }

    #{$wcmmode} & {
        position: static;
    }

    &__container {
        background-color: $blue;
        border-radius: rem(8px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: rem(24px);
        justify-content: space-between;
        height: 271px;

        @include respond-above(lg) {
            @include make-col(10);
            flex-direction: row;
            height: 178px;
            align-items: center;
            margin: 0 auto;
            padding: 0;
        }
    }

    &__label {
        @include t-label-big();

        margin-bottom: rem(16px);

        @include respond-below(lg) {
            line-height: rem(18px);
        }

        @include respond-above(lg) {
            order: 2;
            margin-bottom: 0;
        }
    }

    &__logo {
        margin-bottom: rem(32px);
        max-width: 120px;

        @include respond-above(lg) {
            order: 1;
            margin-bottom: 0;
            max-width: 200px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__location {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -0.5px;
        display: flex;
        margin-bottom: rem(24px);

        em {
            margin-right: rem(8px);
            width: 24px;
            text-align: center;
        }

        @include respond-above(lg) {
            order: 3;
            margin-bottom: 0;
        }
    }

    &__link {
        display: flex;
        align-items: baseline;
        pointer-events: all;
        @include respond-above(lg) {
            order: 4;
        }
    }

    &__social {
        margin-top: auto;
        display: flex;

        @include respond-above(lg) {
            order: 5;
            margin-top: 0;
        }
    }

    &__social-item {
        margin-left: rem(16px);

        &:first-child {
            margin-left: 0;
        }

        a {
            display: block;
            pointer-events: all;
            cursor: pointer;
        }

        em {
            font-size: rem(32px);
        }
    }

    .elem-custom-select {
        @include respond-above(lg) {
            width: 356px;
            bottom: 178px;
            position: absolute;
        }

        #{$wcmmode} & {
            position: relative;
            bottom: auto;
            z-index: 10;
        }
    }
}