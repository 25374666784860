$oneDay:            '.event--one-day';
$sameMonth:         '.event--same-month';
$skeleton-card:     '.skeleton-card';

.elem-card {
    $this-card: &;

    &__event {
        background-color: $blue;
        color: $white;

        @include respond-above(lg) {
            width: 240px;
            display: flex;
            flex-direction: column;

            ~ .elem-card__details {
                flex: 0 0 78.94%;
                max-width: 78.94%;
                padding: 40px 56px;
            }
        }

        #{$wcmmode} & {
            width: 100%;

            .elem-card__event ~ .elem-card__details {
                max-width: none;
                flex: 100%;
            }
        }
    }

    &__date-container {
        display: flex;
        padding: rem(23px);
        //justify-content: space-between;
        justify-content: center;
        align-items: baseline;
        max-width: 400px;
        margin: 0 auto;

        #{$oneDay} & {
            padding: rem(11px) 0 rem(13px) 0;
        }

        #{$sameMonth} & {
            position: relative;
        }

        @include respond-above(lg) {
            height: 100%;
            flex-direction: column;
            align-items: center;
            min-height: 246px;
            padding: 0 rem(11px) 0 rem(11px);

            #{$oneDay} & {
                padding: rem(23px);
            }
        }
    } 

    &__date,
    &__event-city {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__date {
        flex-direction: column;

        + #{$this-card}__date {

            &:before {
                @include karbonLight();

                content: "-";
                position: absolute;
                left: 50%;
                top: 24px;
                font-size: rem(48px);
                line-height: rem(52px);
                margin-left: rem(-8px);
            }

            #{$sameMonth} & {

                &:before {
                    margin: 0 rem(9px);
                    position: relative;
                    left: auto;
                    top: auto;
                }
            }
        }

        @include respond-below(lg) {
            flex-basis: 50%;

            #{$sameMonth} & {
                flex-basis: auto;
                flex-direction: row;
                justify-content: flex-start;
                padding-bottom: rem(22px);
            }
        }

        @include respond-above(lg) {
            position: relative;

            + #{$this-card}__date {
                margin-top: 5px;

                &:before {
                    content: "";
                    width: 45px;
                    border-top: 1px solid $white;
                    opacity: 0.5;
                    top: 0;
                    margin-left: rem(-22.5px);
                }
            }
        }
    }

    &__date-day {
        font-size: rem(48px);
        line-height: rem(52px);

        @include respond-above(lg) {
            font-size: rem(72px);
            line-height: rem(77px);
            letter-spacing: -2px;
        }

        #{$oneDay} & {
            @include respond-above(lg) {
                font-size: rem(96px);
                line-height: rem(96px);
            }
        }
    }

    &__date-month-years {
        @include karbonMedium();
        
        text-transform: uppercase;
        font-size: rem(14px);
        line-height: rem(14px);
        letter-spacing: 2px;
        margin-top: 8px;
        margin-bottom: 10px;
        text-align: center;

        #{$oneDay} &,
        #{$sameMonth} & {
            @include respond-above(lg) {
                font-size: rem(18px);
                line-height: rem(24px);
            }
        }

        @include respond-below(lg) {
            #{$sameMonth} & {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 15px;
                text-align: center;
            }
        }

        @include respond-above(lg) {
            #{$sameMonth} & {
                margin-top: rem(16px);
            }
        }
        
        &:first-child {
            //margin-top: 0;
            margin-bottom: 8px;
        }
        
        &:empty {   // It would be better not to insert this when empty
            display: none;
        }
    }

    &__event-city {
        background-color: var(--shadowWhite);
        padding: 11px;
        text-align: center;
        text-transform: uppercase;
        font-size: rem(14px);
        line-height: rem(18px);
        letter-spacing: 2px;

        .icon-outline_pin {
            margin-right: rem(6px);
            font-size: rem(16px);
        }

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: rem(24px);
            padding: rem(16px);
        }
    }

    &__hours {
        @include karbonMedium();
        font-size: rem(14px);
        line-height: rem(18px);
        letter-spacing: 2px;
        color: $darkGray;
        text-transform: uppercase;
        display: flex;
        margin-bottom: rem(16px);

        #{$skeleton-card} & {
            width: 50%;
            height: 12px;
            margin-bottom: 28px;
            background-color: $grayWhite;
        }


        em {
            margin-right: rem(7px);
        }

        @include respond-above(lg) {
            //margin-bottom: rem(21px);
        }
    }
}