.base-breadcrumb {
    @include container();
    @include content-container();

    position: relative;
    z-index: 110;
    margin-top: 72px;

    @include respond-below(lg) {
        visibility: hidden;
        overflow: hidden;

        .base-detail-page &,
        .base-news-page &,
        &.back--visible {
            visibility: visible;

            nav {
                display: none;
            }
        }
    }

    &__list {
        display: flex;
        padding: 32px 0;

        @include respond-above(lg) {
            padding: 40px 0;
        }
    }

    &__item {
        font-size: rem(16px);
        line-height: rem(24px);
        display: none;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        letter-spacing: -0.5px;

        a {
            text-decoration: underline;
            
            @include mouse-only() {
                &:hover {
                    color: $blue;
                }
            }
        }
        
        &.item--home {
            display: inline-flex;

            a {
                text-decoration: none;
                display: flex;
                padding: 2px 6px;
                align-items: center;
            }

            @include respond-above(lg) {
                .icon-home {
                    font-size: rem(18px);
                }
            }
        }

        &.item--current {
            @include karbonMedium();
            display: inline-flex;
        }

       
        &:nth-last-child(2){
            display: inline-flex;
        }

        .icon-caret_right {
            &:before {
                content:  $icon-caret_right_alt;
            }
            font-size: rem(12px);
            line-height: 8px;
            padding: 11px 11px 5px 11px;
        }

        @include respond-above(lg) {
            font-size: rem(18px);
            display: inline-flex;
        }
    }

    &__back-container {
        display: none;

        @include respond-below(lg) {
            .base-detail-page &,
            .base-news-page &,
            .back--visible & {
                display: flex;
            }
        }
    }

    &__btn-back {
        margin: rem(30px) 0 rem(22px) 0;
        padding: 10px 0;

        span {
            @include  t-text-link();
            color:$black;
            padding-left: 23px;
        }
        
        .icon-arrow_left {
            color:$black;
            font-size: rem(12px);
            line-height: rem(16px);

            &:before {
                position: absolute;
                transform: rotate(180deg);
            }
        }

        .text-white & {
            span,
            .icon-arrow_left {
                color: $white;
            }
        }
    }
}