$all: '[data-history="all"]';
$nexi: '[data-history="nexi"]';
$nets: '[data-history="nets"]';
$sia: '[data-history="sia"]';

.elem-history-card {
    $this-history-card: &;

    &:first-child {
        .step-first & {
            #{$this-history-card}__line-col {
                padding-top: 16px;

                @include respond-above(lg) {
                    padding-top: 32px;
                }

                #{$this-history-card}__dot {
                    top: 0;
                }
            }
        }

        .step-third & {
            #{$this-history-card}__content {
                padding-top: 26px;
            }

            #{$this-history-card}__dot {
                top: 41px;
            }
        }
    }

    @include respond-below(lg) {
        &:last-child {
            .step-third & {
                #{$this-history-card}__events-col {
                    padding-bottom: 58px;
                }
            }
        }
    }

    @include respond-above(lg) {

        &:first-child {
            .step-first & {
                #{$this-history-card}__events-col {
                    padding-top: 3px;
                }
            }

            .step-third & {
                #{$this-history-card}__content {
                    padding-top: 0;
                }

                #{$this-history-card}__year-col,
                #{$this-history-card}__events-col {
                    padding-top: 85px;
                }

                #{$this-history-card}__dot {
                    top: 118px;
                }
            }
        }
    }

    &__container {
        @include container();
        @include content-container();

        .step-second & {
            position: relative;
            z-index: 10;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-left: 26%;
        position: relative;

        .step-first & {
            padding-bottom: 22px;
        }

        .step-second & {
            padding-top: 45px;
            padding-bottom: 26px;
        }

        @include respond-above(lg) {
            @include make-row();
            flex-direction: row;
            padding-left: 0;

            .step-first &,
            .step-second & {
                padding: 0
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        picture {
            @include imageCover();
        }

    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-color: rgba(44, 49, 170, 0.3);
        
        pointer-events: none;
        
        &:after {
            content: '';
            
            background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    &__year-col {
        @include respond-above(lg) {
            @include make-col-ready();
            @include make-col(3);

            .step-second & {
                padding-top: 74px;
            }
        }
    }

    &__year {
        @include nexiNumber();
        font-size: rem(32px);
        line-height: rem(40px);
        letter-spacing: -1px;
        color: $yearTxtMobile;

        .step-first & {
            color: $black;
        }

        .step-second & {
            color: $white;
        }

        @include respond-above(lg) {
            @include t-numbers-2();

            color: $yearTxtDesktop;
            text-align: right;
            letter-spacing: -2px;

            .step-first & {
                color: $yearTxtDesktop;
            }
        }
    }

    &__line-col {
        display: flex;
        justify-content: flex-start;

        .step-second & {
            padding-top: 70px;

            &:before {
                content: '';
                position: absolute;
                width: rem(11px);
                height: rem(11px);
                border-radius: 50%;
                background-color: $white;
                z-index: 10;
                left: calc(33.333% - 5px);
                top:66px;
            }

            &:after {
                content: '';
                height: 66px;
                border-left: 2px solid #DFDFDF;
                position: absolute;
                top: 0;
                left: 33.333%;
            }
        }

        @include respond-below(lg) {
            position: absolute;
            left: 0;
            top:0;
            width: 26%;
            height: 100%;
        }
        
        @include respond-above(lg) {
            @include make-col(3);

            .step-second & {
                position: relative;
                padding-top: 112px;

                &:before {
                    left: calc(66.666% - 7px);
                    top: 108px;
                    width: rem(13px);
                    height: rem(13px);
                }

                &:after {
                    left: calc(66.666% - 2px);
                    height: 95px;
                }
            }
        }
    }

    &__line {
        @include make-col(4);
        height: 100%;
        border-left: 2px solid #DFDFDF;
        position: relative;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.09,.72,.76,1.01);

        @include respond-above(lg) {
            border-left: 0;
            border-right: 2px solid #DFDFDF;
        }

        &.hidden {
            opacity: 0.25;
        }

        .step-first & {
            margin-left: 33.333%;
        }


        &:first-child,
        &:last-child {
            .step-second & {
                border: 0;

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    border-top: 2px solid #DFDFDF;
                }

            }
        }

        &:first-child {
            .step-second & {
                &:before {
                    border-left: 2px solid #DFDFDF;
                    border-top-left-radius: 11px;

                    @include respond-above(lg) {
                        border-top-left-radius: 40px;
                        left: 100%;
                    }
                }
            }
        }

        &:last-child {
            .step-second & {
                &:before {
                    border-right: 2px solid #DFDFDF;
                    border-top-right-radius: 11px;
                    right: 100%;

                    @include respond-above(lg) {
                        border-top-right-radius: 40px;
                        right: auto;
                    }
                }
            }
        }
    }

    &__dot {
        width: rem(11px);
        height: rem(11px);
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: -6px;
        z-index: 10;

        @include respond-above(lg) {
            width: rem(13px);
            height: rem(13px);
            top: 33px;
            left: auto;
            right: -7px;
        }

        #{$nexi} & {
            background-color: $blue;

            .step-second & {
                background-color: transparent;
            }
        }

        #{$nets} & {
            background-color: $concardis;
        }

        #{$sia} & {
            background-color: #AF0834;
        }

        #{$all} & {
            background-color: $blue;
        }
    }

    &__events-col {
        padding-bottom: 32px;

        @include respond-above(lg) {
            @include make-col-ready();
            @include make-col(5);
        
            margin-left: auto;
            padding-top: 10px;
            padding-bottom: 52px;
            padding-left: 18px;

            .step-second & {
                padding-top: 102px;
                padding-bottom: 106px;
            }
        }
    }

    &__event {
        padding-top: 15px;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.09,.72,.76,1.01);

        @include respond-above(lg) {
            padding-top: 24px;

            .step-second & {
                padding-top: 0;
            }
        }

        &.hidden {
            opacity: 0.25;
        }
    }

    &__event-info {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        @include respond-above(lg) {
            margin-bottom: 8px;
        }
    }

    &__event-year {
        @include nexiNumber();
        font-size: rem(18px);
        color: $blue;
        margin-left: 8px;
    }

    &__event-logo {

        #{$nexi} &,
        #{$all} & {
            width: 42px;
        }

        #{$nets} &,
        #{$sia} &  {
            width: 50px;
        }

        
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }

        @include respond-above(lg) {
            #{$nexi} &,
            #{$all} & {
                width: 56px;
            }
    
            #{$nets} &,
            #{$sia} & {
                width: 70px;
            }
        
        }
    }

    &__logo-label {
        font-size: rem(18px);
        line-height: 19px;
        margin-left: 8px;
        color: $blue;

        .step-second & {
            color: $white;
        }
    }

    &__event-description {
        @include t-paragraph-1();
        font-size: rem(16px);
        line-height: rem(22px);
        color: $black;

        @include respond-above(lg) {
            font-size: rem(24px);
            line-height: rem(32px);
            letter-spacing: -0.5px;
        }

        .step-second & {
            color: $white;
        }
    }
}