@mixin bar($background-color, $text-color) {
    @include karbonMedium();
            font-size: rem(24px);
            letter-spacing: - 0.5px;
            line-height: 32px;
            font-weight: 500;

            @include respond-below(lg) {
                font-size: 18px;
                line-height: 24px;
            }
            


    top: 0;
    left: 0;
    background-color: $background-color;
    color: $text-color;
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 16px 0px;
    gap: 16px;
}
    

    .button-container {
        display: flex;
        justify-content: center;
    }

.v1 {
    @include bar(#EFEFF8, #000000);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-primary;
        @extend .btn-link;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
    }
}

.v2{
    @include bar(#2D32AA, white);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-primary-white;
        @extend .btn-link;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
    }
}

.v3 {
    @include bar(#1B1F7C, white);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-primary-white;
        @extend .btn-link;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
    }
}

.v4 {
    @include bar(#EFEFF8, #000000);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-link;
        @extend .btn-secondary-blue;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
        
    }
}

.v5 {
    @include bar(#2D32AA, white);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-secondary;
        @extend .btn-link;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
    }
}

.v6 {
    @include bar(#1B1F7C, white);
    &.is-sticky{
        position: fixed;
    }
    
    .sticky-bar__cta {
        @extend .btn-secondary;
        @extend .btn-link;
        &:hover {
            padding: rem(11px) rem(23px);
            width: 100%;
        }
    }
}
@include respond-below(lg) {
    .sticky-bar {
        padding: 16px; 
        display: flex;
        height: 112px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
        z-index: 99;
    }
}