.layout-cards {
    @include container();
    @include content-container();

    //width: 100%;

    &__title {
        padding-top: 56px;

        h1,h2,h3,h4,h5,h6,p,span {
            font-size: rem(28px);
            line-height: rem(34px);
            color: $black;

            @include respond-above(lg) {
                font-size: rem(40px);
                line-height: rem(40px);
                letter-spacing: -1px;
            }
        }

        @include respond-above(lg) {
            padding-bottom: 24px;
        }
    }

    &__container {
        @include make-row();

        //padding-top: 32px;

        > .base-main__item {
            width: 100%;
        }
    }

    
    .widget-tab__tab-pane &,
    .widget-past-events__tab-pane & {
        padding: 0 !important;
    }

    &--spaceBottom {
        margin-bottom: 40px;

        @include respond-above(lg) {
            margin-bottom: 60px;
        }
    }
}



/********* TEMP - TO DELETE *************/
.widget-contact-cards--spaceBottom {
    margin-bottom: 40px;

    @include respond-above(lg) {
        margin-bottom: 60px;
    }
}
/********* TEMP - TO DELETE *************/
