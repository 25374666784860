%disableScroll {
    // Prevent iOS and Android page scrolling
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

:root,
html {
    @include default-theme;
    @include cssVariables;
    @include karbonRegular();

    font-size: $text-base-size;
    font-weight: normal;
    font-style: normal;
}

html,
body {
    overflow-x: hidden;
    overscroll-behavior-y: none;
}

html {
    height: 100%;
    //height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    scroll-behavior: smooth;
    overflow-y: scroll;

    &.block-scroll {
        @extend %disableScroll;
    }
}

body {
    background-color: $pageBg;
    color: $txt;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    .block-scroll & {
        @extend %disableScroll;

        max-height: 100vh;
        //max-height: -webkit-fill-available;
        height: 100vh;
    }

    &.body-grey {
        background-color: $grayWhite;
    }

    &.body-white {
        background-color: $white;
    }

    @include respond-above(lg) {
        min-height: 100vh;
    }
}
