$step1: '.step-first';
$step2: '.step-second';
$step3: '.step-third';
$all: '[data-history="all"]';
$nexi: '[data-history="nexi"]';
$nets: '[data-history="nets"]';
$sia: '[data-history="sia"]';
$btn-nexi: '[data-target="nexi"]';
$btn-nets: '[data-target="nets"]';
$btn-sia: '[data-target="sia"]';

.widget-history {
    $this-history: &;

    background: $white;
    color: $black;

    &__step {
        &.step-second {
            position: relative;
        }
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__filters-bar {
        background-color: $filterBg;
        width: 100%;
        padding: 13px 0;
        transition: top 0.3s ease-out;

        @include respond-above(md) {
            padding: 26px 0;
        }
        
        &.sticky {
            position: fixed;
            top: 72px;
            z-index: 100;

            @include respond-below(lg) {
                &.sticky-scrolled {
                    top: 0;
                }
            }
        }

        #{$this-history}__container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include respond-below(md) {
                padding: 0 20px;
            }
            
            @include respond-above(md) {
                flex-direction: row;
                justify-content: flex-start;
            }
        }
    }

    &__filters-label {
        font-size: rem(18px);
        line-height: rem(19px);
        letter-spacing: -0.5px;
        margin-bottom: 8px;

        @include respond-above(md) {
            display: flex;
            width: 26%;
            align-items: center;
            padding-right: 0.5rem;
            margin-bottom: 0;
        }

        @include respond-above(lg) {
            @include make-col(3);
            width: auto;
            margin-left:-8px;
            justify-content: flex-end;
        }
    }

    &__filters-tabs {
        display: flex;
        justify-content: flex-start;
         
        @include respond-above(md) {
            @include make-col-ready();
            width: auto;
            justify-content: center;
            padding-left: 0;
        }

        @include respond-above(lg) {
            @include make-col(4);
            padding-left: 0.5rem;
        }
    }

    &__btn-tab {
        text-transform: uppercase;
        font-size: rem(16px);
        color: $blue;
        width: 99px;
        height: 37px;
        padding: 0 25px;
        border: 1px solid $blue;
        border-radius: 18px;
        text-align:center;
        position: relative;
        margin-left: 8px;
        letter-spacing: 1px;
        background-color: transparent;
        transition: background .3s cubic-bezier(.09,.72,.76,1.01), border .2s cubic-bezier(.09,.72,.76,1.01);
        
        &:first-child {
            margin-left: 0;
        }

        @include respond-above(md) {
            width: 89px;
            margin: 0 4px;
        }

        &.selected {
            background-color: $white;
            border-color: $white;

            .icon-check,
            .icon-dot {
                display: block;
            }
        }

        @include mouse-only(){
            &:hover {
                background-color: $white;
                border-color: $white;
            }
        }

        .icon-check,
        .icon-dot {
            display: none;
            position: absolute;
        }

        .icon-check {
            left: 8px;
            top: 11px;
        }

        .icon-dot {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            top: 14px;
            right: 9px;
        }
    }

    #{$btn-nexi} {
        .icon-dot {
            background-color: $blue;
        }
    }

    #{$btn-nets} {
        .icon-dot {
            background-color: $concardis;
        }
    }

    #{$btn-sia} {
        .icon-dot {
            background-color: #AF0834;
        }
    }
}