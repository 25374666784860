.widget-tile {
    $this-tile: &;

    .base-main__item > & {
        @include container();
        @include content-container();
    }

    &__container {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $black;
            opacity: 0.45;
            z-index: 1;
        }

        @include respond-above(lg) {
            border-radius: 0;
        }
    }

    &__picture {
        @include imageCover;

        position:absolute;
        z-index: 0;
    }

    &__content {
        @include make-col(12);
        padding: 24px;
        position: relative;
        min-height: 327px;
        display: flex;
        flex-direction: column;
        color: $white;
        z-index: 2;

        @include respond-above(lg) {
            @include make-col(8);
            padding: 96px 0 96px 96px ;
            min-height: 456px;
            justify-content: center;
        }
    }

    &__title {
        @include t-h3-title();
        margin-bottom: 16px;
        
        @include respond-below(lg) {
            min-height: 80px;
            display: flex;
            align-items: flex-end;
        }
        
        @include respond-above(lg) {
            margin-bottom: 25px;
        }

        @include mouse-only() {
            a:hover {
                text-decoration: underline;
            }
        }
    }

    &__text {
       @include t-paragraph-1();
    }

    &__cta {
        margin-top: 71px;
        display: flex;
        justify-content: center;
        
        @include respond-above(lg) {
            margin-top: 56px;
            justify-content: flex-start;
        }
    }

    & + #{$this-tile} {
        margin-top: 24px;

        @include respond-above(lg) {
            margin-top: 48px;
        }
    }
}