.elem-image {
    overflow: hidden;
    position: relative;

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
    }
    
    &--border {
        border-radius: rem(8px);
    }

    &--limited-height {

        img,
        source {
            max-height: 262px;
            object-fit: cover;
            font-family: 'object-fit: cover;';    // IE11 polyfill support

            @include respond-above(lg) {
                max-height: 531px;
            }
        }
    }
}