// MODIFIER CLASSES
$width100: '.column__width--100';
$width50: '.column__width--50';
$width33: '.column__width--33';
$width25: '.column__width--25';
$width33-66: '.column__width--33-66';
//$width66-33: '.column__width--66-33';
$width41-58: '.column__width--41-58';
$width58-41: '.column__width--58-41';
$align-center: '.align-center';
$align-bottom: '.align-bottom';
$reverse-order: '.reverse-order'; // only for mobile
$narrow: '.layout-panel__narrow';


.layout-panel {
    $this-panel: &;
    padding-left: 0;
    padding-right: 0;
    // margin:-1px; //Prevent unwantend border

    &.p-t-def {
        padding-top: rem(40px);

        @include respond-above(lg) {
            padding-top: rem(64px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }

    &__container {
        @include container();
        @include content-container();

        @include respond-above(lg) {
            .base-news-page & {
                max-width: calc( 947px + 10vw );
            }
        }

        @include respond-above(xl) {
            .base-news-page & {
                max-width: calc( 947px + 20vw );
            }
        }

        #{$narrow} & {          
            @include respond-above(lg) {                
                max-width: calc( 947px + 10vw );             
            }
    
            @include respond-above(xl) {                
                max-width: calc( 947px + 20vw );               
            }     
        }
    }

    &__row {
        @include make-row();
        
        @include respond-below(lg) {
            #{$reverse-order} & {
                flex-direction: column-reverse;
            }
        }
    }

    &__column {
        @include make-col-ready();

        display: flex;
        flex-direction: column;

        @include respond-below(lg) {
            #{$this-panel}__column ~ & {
                margin-top: rem(16px);
            }

            #{$reverse-order} &:last-child {
                margin-top: 0px;
            }

            #{$reverse-order} &:first-child {
                margin-top: rem(16px);
            }
        }
        
        @include respond-above(lg) {
            #{$width100} & {
                @include make-col(12);
            }

            #{$width50} & {
                @include make-col(6);
            }

            #{$width25} & {
                @include make-col(3);
            }

            #{$width33} & {
                @include make-col(4);
            }

            #{$width33-66} &:last-child {
                @include make-col(8);
            }

            #{$width33-66} &:first-child {
                @include make-col(4);
            }

            #{$width41-58} &:last-child { 
                @include make-col(7);
            }

            #{$width41-58} &:first-child,
            #{$width58-41} &:last-child {
                @include make-col(5);
            }

            #{$width58-41} &:first-child {
                @include make-col(7);
            }

            #{$width58-41} &:last-child {
                padding-left: rem(38px);
            }

            #{$width41-58} &:first-child {
                padding-right: rem(38px);
            }

            #{$align-center} & {
                justify-content: center;
            }

            #{$align-bottom} & {
                justify-content: flex-end;
            }
        }

        hr {
            margin-top: 24px;
            margin-bottom:24px;

            @include respond-above(lg) {
                margin-top: 32px;
                margin-bottom:16px;
            }
        }
    }
}