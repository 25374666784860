@mixin btn-tab {
    font-size: rem(16px);
    line-height: rem(16px);
    letter-spacing: 2px;
    color: $black;
    border-top: var(--tabBorder);
    border-bottom: var(--tabBorder);
    border-right: var(--tabBorder);
    padding: 11px;
    background: $white;
    transition: all .3s cubic-bezier(.09,.72,.76,1.01);

    &:first-child {
        border-top-left-radius: rem(8px);
        border-bottom-left-radius: rem(8px);
        border-left: var(--tabBorder);
    }

    &:last-child {
        border-top-right-radius: rem(8px);
        border-bottom-right-radius: rem(8px);
    }

    &.active {
        @include karbonMedium();

        border-color: var(--tabBorderActive);
        background: $blue;
        color: $white;
    }

    @include mouse-only() {

        &:hover {
            @include karbonMedium();

            border-color: var(--tabBorderActive);
            background: $blue;
            color: $white;
        }
    }
}

.widget-tab {
    &__container {
        @include container();
        @include content-container();
    }

    &__tab-group {
        padding-top: 26px;
        display: flex;
    }

    &__btn {
        @include btn-tab();
    }

    &__tab-pane {
        display: none;
        color: $black;
        padding-bottom: 80px;
        
        &.active {
            display: block;
        }

        #{$wcmmode} {
            display: block;
        }
    }
}