.widget-logos-grid {
    $this-logos: &;

    background: $white;
    padding-left: 0;
    padding-right: 0;

    &.p-t-def {
        padding-top: rem(32px);
    }

    &.p-b-def {
        padding-bottom: rem(32px);
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__row {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(6);

        margin-bottom: rem(24px);

        @include respond-above(md) {
            @include make-col(4);
        }
        
        @include respond-above(lg) {
            @include make-col(3);

            margin-bottom: rem(32px);
        }
    }

    &__item-link {
        display: block;
        
        @include mouse-only(){
            &:hover {

                #{$this-logos}__item-image {
                    border:1px solid transparent;
                    box-shadow: $card-shadow;
                }
    
            }
        }
        
    }

    &__item-image {
        border-radius: rem(6px);
        overflow: hidden;
        height: 130px;
        border: 1px solid $blue;
        padding: 24px;
        transition: all border .2s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            display: block;
            height: 100%;
            width: auto;
            min-width: auto;
            max-width: 100%;
            min-height: 100%;
            margin: 0 auto;
        }
    }
    
    &__item-text {
        @include karbonMedium();
        text-transform: uppercase;
        text-align: center;
        color: $blue;
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: 2px;
        margin-top: 8px;
    }
}