.elem-back-button {
    width: auto;
    background-color: transparent;

    span {
        @include  t-text-link();
        color:$black;

        em {
            color:$black;
            font-size: rem(12px);
            padding-right: rem(18px);
        }
    }
    /* STATE NOT DEVELOPED YET DUE TO LACK IN DESIGN 
    &:hover {
        background-color: $buttonPrimaryBgHover;
    }

    &:disabled {
        background-color: $buttonPrimaryBgDisabled;
    } */
}