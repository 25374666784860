.elem-panel-card {
    $this-panel-card: &;
    
    @include container();
    @include content-container();
    
    margin-top:rem(32px);
    margin-bottom:rem(32px);

    .layout-panel & {
        padding: 0 !important;
        max-width: none;
        margin-bottom: 0;
    } 

    .widget-tab & {
        padding: 0 !important;
        max-width: none;
        margin-bottom: 0;

        + #{$this-panel-card} {
            margin-top: rem(40px);
        }
    }

    &__wrapper {
        width: 100%;
        border-radius:8px;
        background-color: $white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: rem(24px);

        @include respond-above(lg) {
            padding: rem(24px);
        }
    }
}