//DESKTOP
.widget-analyst {
	background-color: transparent;
	margin-top: 48px;
	margin-bottom:48px;
	@include respond-below(lg) {
		padding-bottom: 0;
	}
	&__title {
		@include karbonRegular();
		font-size: rem(32px);
		letter-spacing: -1px;
		line-height: 40px;
		color: $black;
		margin-bottom: 32px;
		@include respond-below(lg) {
			font-size: rem(24px);
		}
	}
	&__wrapper {
		/* @include container(); */
		@include content-container();
		padding-bottom: 36px !important;
		@include respond-below(lg) {
			display: none;
		}
	}
	&__heading {
		padding: 32px 15px;
		display: flex;
		flex-direction: row;
		width: 100%;
		border-bottom: 1px solid $lightGray;
		justify-content: left;
		align-items: center;
		@include karbonMedium();
		color: $black;
		font-size: rem(14px);
		letter-spacing: 1.56px;
		line-height: 24px;
		text-transform: uppercase;
		@include respond-below(lg) {
			display: none;
		}
		&-item__broker {
			width: 30%;
		}
		&-item__analyst {
			width: 20%;
		}
		&-item__contacts {
			width: 30%;
		}
	}
	&__row {
		padding: 16px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $lightGray;
		justify-content: left;
		align-items: center;
		@include karbonRegular();
		color: $black;
		font-size: rem(18px);
		letter-spacing: 0;
		line-height: 32px;
		@include respond-below(lg) {
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			font-size: rem(16px);
		}
		&-item__analyst {
			width: 20%;
		}
		&-item__telephone {
			width: 20%;
		}
		&-item__email {
			width: 30%;
		}
		&-item__brand {
			width: 30%;
			display: flex;
			flex-direction: row;
			align-items: center;
			@include respond-below(lg) {
				width: 70% !important;
			}
			p {
				font-size: rem(24px);
				line-height: 24px;
				color: $black;
				overflow-wrap: break-word;
			}
		}
	}
}

// MOBILE
.widget-analyst__mobile {
	@include respond-above(lg) {
		display: none;
	}
}

.widget-analyst {
	margin-bottom: 48px !important;
	margin-top: 48px !important;

	&__wrapper-mobile {
		@include content-container();
	}
	&__item {
		padding-bottom: 21px;
		border-top: 1px solid $lightGray;
		border-bottom: 1px solid var(--lightGray);
		margin-top: -1px;
	}
	&__brand-row {
		padding-top: 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: left;

        .elem-card__logo{
            @include respond-below(lg) {
                width: 48px !important;
                height: 48px !important;
            }
            img {
                @include respond-below(lg) {
                    max-width: 36px;
                    height: auto;
                }
            }
        }
	}
	
	&__brand-item__text {
		//margin-left: 20px;
		margin-bottom: 10px;
		.brand,
		p {
			color: $black;
			font-size: rem(18px);
			line-height: 22px;
		}
		.text,
		p {
			font-size: rem(16px);
		}
	}
	&__contacts-row {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: left;
		p {
			color: $black;
			font-size: rem(16px);
			line-height: 22px;
		}
	}
}