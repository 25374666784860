@mixin selectStyle() {
    padding: 11px 40px 11px 16px ;
    border: 1px solid $white;
    border-radius: 8px;
    font-size: rem(18px);
    line-height: rem(24px);
    background-color: rgba(255,255,255,0.1);

    &:disabled {
        opacity: 0.7;
    }

    @include respond-above(lg) {
        padding: 11px 16px;
    }
}

@mixin selectArrow() {

    &::before {
        @include nexiIcon();
        
        content: $icon-caret_up;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -45%) rotate(180deg);
        pointer-events: none;

        @include respond-above(lg) {
            //right: 30px;
            font-size: 22px;
        }
    }
}

@mixin filterStyle() {
    padding: 8px 16px;
    border: 1px solid $gray;
    background-color: $white;
    border-radius: 8px;
    color: $gray;
    font-size: rem(18px);
    line-height: rem(20px);
    max-height: rem(43px);
    overflow: hidden;
}

@mixin filterArrow() {

    &::before {
        color: $black;

        @include respond-above(lg) {
            right: 14px;
            font-size: 16px;
        }
    }
}

@mixin optionCircle() {

    &::after {
        content: '';
        display: inline-block;
        width: 17px;
        height: 17px;
        position: absolute;
        top: calc(50% - 8px);
        right: 17px;
        border: 1px solid $grayWhite;
        border-radius: 50%;
        pointer-events: none;
    }

    @include mouse-only() {
        &:hover::after {
            border: 1px solid $white;
        }
    }
}

@mixin optionSelected() {

    &::after {
        @include nexiIcon();
        
        content: $icon-check;
        position: absolute;
        top: calc(50% - 9px);
        right: 17px;
        width: auto;
        height: auto;
        border: none;
        pointer-events: none;
        color: $blue;
        font-size: rem(14px);
    }

    @include mouse-only() {
        &:hover::after {
            border: none;
        }
    }
}



.elem-custom-select {
    position: relative;

    select {
        @include selectStyle();
        @include karbonRegular();

        position: relative;
        appearance: none;
        margin: 0;
        width: 100%;
        cursor: inherit;
        line-height: inherit;
        
        @include respond-above(lg) {
            max-width: 356px;
        }
    }

    &.form-select {
        
        select {
            border-color: $black;
        }

        &::before {
            top: auto;
            bottom: 3px;

            @include respond-above(lg) {
                bottom: -4px;
            }
        }
    }

    option {
        color: $black;
    }

    @include respond-below(lg) {

        @include selectArrow();
    }
}

.custom-select {
    $this: &;

    position: relative;
    width: 100%;

    select {
        
        @include respond-above(lg) {
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }
    }

    &--disabled {

        #{$this}__opener {
            cursor: default;
            opacity: 0.7;

            @include mouse-only() {
                &:hover {
                    background-color: rgba(255,255,255,0.1);
                }
            }
        }
    }

    .list-filter.active & #{$this}__opener,
    .form-select.active & #{$this}__opener {
        color: $black;
    }

    &--open {

        #{$this}__opener {
            color: $gray;
            background-color: $white;
            border-bottom: 1px solid $gray;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: $bg-shadow;
            transition: background-color .2s ease-out,
                        border-radius 0s ease-out;

            &::before {
                color: $black;
                transform: translate(0, -45%);
            }

            .list-filter &,
            .form-select & {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                color: $black;
            }

            @include mouse-only() {
                &:hover {
                    background-color: $white;
                }
            }
        }

        #{$this}__panel {
            max-height: calc(35vh + 50px);
            box-shadow: $bg-alt-shadow;

            .list-filter &,
            .form-select & {
                box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                border: 1px solid $gray;
                border-top: none;
            }

            .form-select & {
                border-color: $black;
            }

            .widget-modal__form & {
                max-height: calc(22vh + 50px);
            }
        }

        #{$this}__options {
            max-height: 35vh;
            overflow: auto;

            /*@include respond-above(lg) {
                max-height: $custom-select-list-max-height;
            }*/

            .widget-modal__form & {
                max-height: 22vh;
            }
        }
    }

    &__opener {
        @include selectStyle();
        @include selectArrow();

        display: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: background-color .2s ease-out,
                    border-radius .1s ease-out .2s;

        @include mouse-only() {
            &:hover {
                background-color: rgba(255,255,255,0.2);
            }
        }

        @include respond-above(lg) {
            display: block;
            padding: 20px 24px;
            border-radius: 15px;
            font-size: rem(24px);
            line-height: rem(24px);

            .list-filter &,
            .form-select & {
                @include filterStyle();
                @include filterArrow();

                line-height: rem(24px);
                padding-right: 30px;
            }

            .form-select & {
                border-radius: 8px;
                border-color: $black;
            }
        }
    }

    &__panel {
        position: absolute;
        z-index: 100;
        left: 0;
        //top: 100%;
        width: 100%;
        overflow: hidden;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        background-color: $white;
        max-height: 0;
        transition: max-height .2s ease-out;

        /*&--above {
            top: auto;
            bottom: 100%;
        }*/

        @include respond-above(lg) {
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;

            .list-filter &,
            .form-select & {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
                transition: max-height .2s ease-out,
                            border .2s ease-out;
            }
        }
    }

    &__options {
        position: relative;
        width: 100%;
        z-index: 1;

        // Perfect Scrollbar style
        .ps__thumb-y {
            right: 12px;
            width: 6px;

            .list-filter &,
            .form-select & {
                right: 5px;
            }
        }

        .ps__rail-y {
            width: 40px;
            margin: 16px 0;

            .list-filter &,
            .form-select & {
                width: 16px;
            }

            &:hover,
            &:focus,
            &.ps--clicking {

                .ps__thumb-y {
                    right: 11px;
                    width: 8px;

                    .list-filter &,
                    .form-select & {
                        right: 4px;
                    }
                }
            }
        }
    }

    &__option {
        position: relative;
        padding: 11px 22px;
        font-size: rem(18px);
        line-height: rem(24px);
        color: $black;
        cursor: pointer;

        label {
            display: block;
            padding: 10px;
        }

        &[data-value='placeholder'] {
            display: none;
        }

        &:active,
        &:hover,
        &--selected {
            @include karbonMedium();
            background-color: $grayWhite;

            .list-filter & {
                color: $blue;
            }
        }

        .list-filter & {
            @include optionCircle();

            &--selected {
                @include optionSelected();
            }
        }

        @include respond-above(lg) {
            padding: 16px 32px;
            font-size: rem(20px);
            line-height: rem(24px);

            .list-filter &,
            .form-select & {
                padding: 10px 36px 10px 18px;
                font-size: rem(18px);
                line-height: rem(20px);
            }
        }
    }

    /*&__panel-item {
        
        .list-filter & {
            
        }
    }*/

    &__apply-btn {
        @include karbonMedium();

        font-size: rem(14px);
        line-height: rem(18px);
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $blue;
        text-align: center;
        padding: 14px 18px;
        width: 100%;

        &[disabled] {
            opacity: 0.7;
            color: gray;
            cursor: default;

            span {
                display: none;
            }
        }
    }

    @include respond-above(lg) {
        //position: absolute;
        max-width: $custom-select-max-width;

        .list-filter & {
            width: 220px;
        }

        .form-select & {
            
        }
    }
}
