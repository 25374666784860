.elem-text {
    $this-text: &;

    color: $black;

    .base-main__item > & {
        @include container();
        @include content-container();
    }

    .base-main__item .base-main__item > & {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: none;
        margin-left: 0;
    }

    &--body1,
    &--body2 {
        display: block;
    }

    &--body1 {
        @include t-paragraph-1();

        @include respond-above(lg) {
            margin-bottom: rem(32px);
        }
    }

    &--body2 {
        @include t-paragraph-2();
    }

    &--quote {
        @include t-h4-title();
    }

    &--gray {
        color: $darkGray;
    }

    a {
        color: $blue;

        @include mouse-only() {
            &:hover {
                color: $darkBlue;
            }
        }

        .panel-blue & {
            color: $white;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        a {
            text-decoration: underline;
            display: inline-block;
        }
    }

    .elem-title__h1,
    .elem-title__h2,
    .elem-title__h3,
    .elem-title__h4,
    .elem-title__h5 {
        display: block;
    }

    .elem-title__h1 {
        @include t-h1-title();

        margin-bottom: rem(40px);

        @include respond-above(lg) {
            margin-bottom: rem(48px);
        }
    }

    .elem-title__h2 {
        @include t-h2-title();

        margin-bottom: rem(40px);

        @include respond-above(lg) {
            margin-bottom: rem(48px);
        }
    }

    .elem-title__h3 {
        @include t-h3-title();

        margin-bottom: rem(32px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    .elem-title__h4 {
        @include t-h4-title();

        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    .elem-title__h5 {
        @include t-h5-title();

        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    p {
        margin-bottom: rem(24px);
    }

    b {
        @include karbonMedium();

        //font-weight: normal;
    }

    &--link-email {
        @include t-text-link();
        position: relative;
        padding-left: 24px;
        display: inline-block;

        &:before {
            @include nexiIcon();
            content: $icon-email;
            position: absolute;
            left: 0;
            top: 1px;
            font-size: rem(11px);
        }
    }

    &--link-arrow,
    &--link-modal {
        @include t-text-link();
        position: relative;
        padding-right: 24px;
        display: inline-block;

        &:after {
            @include nexiIcon();
            content: $icon-arrow_right;
            color: $blue;
            position: absolute;
            right: 0px;
            top: 1px;
            font-size: rem(12px);
        }

        .panel-blue & {
            color: $white;

            &:after {
                color: $white;
            }
        }
    }

    &--link-modal {
        cursor: pointer;
        display: block;
        color: $blue;
    }

    #{$this-text}--link-arrow,
    #{$this-text}--link-modal,
    #{$this-text}--link-email {
        a {
            text-decoration: none;
        }
    }

    .panel-blue & {
        color: $white;

        hr {
            opacity: 0.5;
        }
    }

    .elem-panel-card & {
        padding: 0 rem(24px) rem(24px) !important;
        margin: 24px 0 24px 24px;

        @include respond-above(lg) {
            padding: 0 0 rem(24px) 0 !important;
            margin: 24px 0 24px 0px;
        }
    }

    .base-news-page & {
        margin: 0 rem(10px);

        @include respond-above(lg) {
            margin: 0;
        }
    }

    & + .elem-unordered-list {
        margin-top: 40px;
    }

    /* LISTS STYLES */
    ul {
        list-style: disc;
        //list-style: none;
        //margin: 0;
        //padding: 0;
        padding-left: 0;
        margin-left: 23px;
    }

    ul ul {
        list-style: circle;
        padding-left: 0;
        margin: 8px 0 0 24px;

        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ul ul ul {
        list-style-type: square;
        padding-left: 0;
        margin: 8px 0 0 24px;

        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ul li {
        font-size: 1.5rem;
        line-height: 2rem;

        @include respond-below(lg) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    ul ol {
        margin-top: 8px;
    }

    ol {
        padding-left: 0;
        margin-left: 23px;
        list-style-type: decimal;

        @include respond-below(lg) {
            margin-left: px;
        }
    }

    ol.base-breadcrumb__list {
        margin: 0; // Avoid ol margin on breadcrumbs
    }

    ol ol {
        list-style-type: lower-alpha;
        padding-left: 0;
        margin: 8px 0 0 24px;

        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol ol ol {
        list-style-type: square;
        padding-left: 0;
        margin: 0 0 8px 24px;

        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol ol ul {
        list-style-type: square;
        padding-left: 0;
        margin: 8px 0 0 24px;

        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol li,
    ul li {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 8px;

        @include respond-below(lg) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    ol,
    ul {
        p {
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 16px 0 16px 0;

            @include respond-below(lg) {
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
        }
    }

    &.layout-wrap-text__text {
        ul {
            @include respond-below(lg) {

                 list-style-type: none;
                // margin-left: 23px;
                // list-style-position: inside;
                 li {
                     position: relative;
                     &:before {
                         content: "•";
                         position: absolute;
                         left: -18px;
                         font-size: rem(30px); 
                      }
                 }
                // li::marker {
                    
                // }
                
            }
        }
        }
  
}