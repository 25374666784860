.elem-attachment {
    $this-attachment: &;

    &.p-b-def {
        
        @include respond-above(lg) {
            padding-bottom: rem(120px);

            .elem-panel-card & {
                padding-bottom: 0;
            }
    
            .layout-panel & {
                padding-bottom: rem(32px);
            }
        }
    }

    &__wrapper {
        @include content-container();

        .layout-panel &,
        .elem-panel-card & {
            padding: 0 !important;
            max-width: none;
        }
    }

    &__heading {
        padding: rem(40px) 0 rem(24px) 0;
        display: flex;
        width: 100%;
        border-bottom: 1px solid $lightGray;

        @include respond-above(lg) {
            //padding: 64px 0 40px 0;
            padding: 40px 0 40px 0;
            justify-content: space-between;
            align-items: center;
        }

        .elem-panel-card & {
            padding: 0 rem(24px) rem(24px);
            
            @include respond-above(lg) {
                padding: rem(24px) 0 rem(48px) 0;
            }

            + #{$this-attachment}__content {
                border-top: 0;
            }
        }
    }

    &__title {
        @include t-label-big();
        color: $black;

        @include respond-above(lg) {
            line-height: rem(40px);
        }

        &-documents {
            @include karbonRegular();
            font-size:rem(28px);
            letter-spacing: -0.5px;
            line-height: rem(40px);

            @include respond-above(lg) {
                font-size: rem(40px);
                letter-spacing: -1.5px;
                line-height: rem(48px);
            }
        }

        &-generic {
            @include karbonRegular();
            font-size:rem(28px);
            letter-spacing: -0.5px;
            line-height: rem(40px);

            @include respond-above(lg) {
                font-size: rem(32px);
                letter-spacing: -1px;
                line-height: rem(40px);
            }
        }

        .panel-blue &,
        .panel-blue #{$this-attachment}-documents,
        .panel-blue #{$this-attachment}-generic {
            color: $white;
        }
    }

    &__footer {
        //padding: 65px 0 40px 0;
        padding-top: 24px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

         .elem-attachment__title {
             visibility: hidden;

             .panel-blue & {
                 color:$white;
             }
         }

        @include respond-below(lg) {
            display: none;
        }
    }

    &__cta {
        @include respond-below(lg) {
            display: none;
        }
    }

    &__content {
        padding: rem(24px) 0;
        border-bottom: 1px solid $lightGray;
        
        @include respond-above(lg) {
            padding: rem(20px) 0;
        }

        .elem-panel-card & {
            border-top: 1px solid $lightGray;
            border-bottom: 0;

            @include respond-below(lg) {
                padding: rem(24px);
            }

        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;

        em {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: rem(32px);
            color: $darkGray;
        }

        .icon-download {
            color: $black;

            .panel-blue & {
                color: $white;
            }
        }

        @include mouse-only() {
            &:focus,
            &:hover {
                p {
                    color: $blue;

                    .panel-blue & {
                        color: $white;
                    }
                }

                .elem-attachment__file-title {

                    .panel-blue & {
                        text-decoration: underline;
                    }
                }

                .elem-attachment__file-date {
                    color: $txtAlt;
                }

                .icon-download {
                    background-color: rgba(0,0,0,0.05);
                    border-radius: 50%;

                    .panel-blue & {
                        background-color: $black;
                        opacity: 0.5;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &__content-text {
        @include cut-words;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //width: 100%;
        margin-left: rem(16px);
        margin-right: auto;
        padding-right: rem(16px);
        justify-content: center;
    }
    
    &__download {
        width: 24px;
        height: 24px;
        color: $black;

        @include respond-above(lg) {
            width: 48px;
            height: 48px;
        }
    }

    &__file-date {
        @include karbonRegular();
        font-size: rem(14px);
        color: $txtAlt;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 18px;
        text-decoration: none;

        .panel-blue & {
            color: $white;
        }
    } 

    &__file-title {
        @include karbonRegular();

        font-size: rem(18px);
        color: $black;
        letter-spacing: -0.5px;
        line-height: 24px;
        display: flex;

        @include respond-below(lg) {
            font-size: rem(16px);
        }

        .panel-blue &{
            color: $white;
        }
    }
}


.elem-attachment_ctaBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 80px;

    @include respond-above(lg) {
        display: none;
    }
}

.elem-attachment__hideElement {
    display: none;
}

.elem-attachment__columnSx-title {

    .panel-blue &{
        color: $white;
    }
}

.elem-attachment__columnDx-description {

    .panel-blue &{
        color: $white;
    }
}