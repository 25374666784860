.widget-accordion-panel {
    $this-accordion : &;

    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column-reverse;

    &__opener {
        width: 100%;

        .btn-text-link {
            padding: 40px 0;
            color: $blue;
        }
    }

    &__button {
        @include t-label-big();
        display: flex;
        color: $blue;
        width: 100%;
        justify-content: center;
        padding-top: 12px;
        border-top: 1px solid $lightGray;

        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: rem(14px);
        }

        &[aria-expanded="false"]{
            #{$this-accordion}__button-open {
                display: inline-flex;
            }

            #{$this-accordion}__button-close {
                display: none;
            }
        }

        &[aria-expanded="true"]{
            #{$this-accordion}_button-close {
                display: inline-flex;
            }

            #{$this-accordion}__button-open {
                display: none;
            }
        }
    }

    &__button-open,
    &__button-close {
        align-items: center;
        em {
            font-size: rem(14px);
            margin-left: 12px;
        }
    }

    &__collapse {
        height: 0;
        width: 100%;
        overflow: hidden ;
        transition: height 0.2s ease-out;
        

        &.show {
            display: block;
        }
    }

    &__content {
        padding: 56px 0 36px 0;

        @include respond-above(lg) {
            padding: 64px 0;
        }
    }
}