.elem-cta {
    
    .base-main__item > & {
        width: 100%;
        padding-right: rem(25px);
        padding-left: rem(25px);
        margin: 0 auto;

        @include respond-above(lg) {
            padding-right: 5vw !important;
            padding-left: 5vw !important;
            max-width: calc( 1140px + 10vw );
        }

        @include respond-above(xl) {
            padding-right: 10vw !important;
            padding-left: 10vw !important;
            max-width: calc( 1140px + 20vw );
        }
    }

    .base-main__item .base-main__item > & {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: none;
    }

    &.p-t-def {
        padding-top: rem(40px);

        @include respond-above(lg) {
            padding-top: rem(64px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }

    &__external-link {
        @include t-paragraph-1();

        display: inline-block;

        span {
            text-decoration: underline;
        }

        [class*="icon"] {
            margin-left: 5px;

            @include respond-above(lg) {
                font-size: rem(20px);
            }
        }
    }

    &__download {
        .icon-download {
            font-size: rem(14px);
        }
    }
}