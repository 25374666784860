//
//  THEMES
//––––––––––––––––––––––––––––––––––––––––––––––––––

//Default color theme
@mixin default-theme {

    // Primary
    --azure: #00b8de;
    --blue: #2d32aa;
    --red: #f9423a;
    --green: #48d597;
    
    // Secondary
    --darkBlue: #191e80;
    --black: #000000;
    --blackGray: #323232;
    --darkerGray: #747678;
    --darkGray: #9a9b9c;
    --gray: #b2b4b3;
    --lightGray: #c9cac8;
    --lighterGray: #e0e1dd;
    --shadowGray: rgba(0,0,0,0.5);
    --grayWhite: #f0f0f0;
    --dirtyWhite: #fafafa;
    --white: #ffffff;
    --shadowWhite: rgba(255,255,255,0.1);
    --shadowWhiteHover: rgba(255,255,255,0.15);
    
    // Commons
    --shadow: rgba(32,32,32,0.15);
    --blackGradient: linear-gradient(0deg, rgba(0, 0, 0, 0.81) 0%, rgba(0, 0, 0, 0.2) 100%);

    // Text
    --txt: var(--white);        // TEMP
    --txtAlt: var(--darkGray);  // TEMP
    --txtBright: var(--black);  // TEMP

    // Background
    --pageBg: #f6f7f9;
    //--bgDark: var(--black);

    // Buttons
    --buttonPrimaryBg: var(--blue);
    --buttonPrimaryBgHover: var(--darkBlue);
    --buttonPrimaryBgDisabled: var(--lightGray);
    --buttonPrimaryTxt: var(--white);
    --buttonPrimaryBorder: 1px solid var(--blue);
    --buttonPrimaryBgAlt: var(--white);
    --buttonPrimaryBgAltHover: var(--dirtyWhite);
    --buttonPrimaryBgAltDisabled: var(--lightGray);
    --buttonPrimaryTxtAlt: var(--blue);
    --buttonPrimaryTxtAltHover: var(--white);
    --buttonPrimaryBorderAlt: 1px solid var(--white);
    --buttonSecondaryBg: transparent;
    --buttonSecondaryBgHover: var(--shadowWhiteHover);
    --buttonSecondaryBgDisabled: var(--lightGray);
    --buttonSecondaryBorder: 1px solid var(--white);
    --buttonSecondaryTxt: var(--white);
    --buttonSecondaryTxtBlue: var(--blue);
    --buttonSecondaryBgAlt: transparent;
    --buttonSecondaryBgAltHover: rgba(45,50,170,0.05);
    --buttonSecondaryBgAltDisabled: var(--lightGray);
    --buttonSecondaryBorderAlt: 1px solid var(--blue);
    --buttonSecondaryTxtAlt: var(--blue);

    // Tabs
    --tabBorder: 1px solid #d3d3d3;
    --tabBorderActive: var(--blue);
    --tabGroupBGDefault: #F6F7F9;

    // Header
    --headerBg: transparent;
    --headerBgHover: rgba(0,0,0,0.3);
    --headerBgAlt: transparent;
    --headerBgAltHover: rgba(0,0,0,0.8);
    --navItemActive: 2px solid #fff;

    // Card
    --cardSharePanelBg: rgba(0,0,0,0.3);
    --cardSharePanelBgAlt: rgba(0,0,0,0.5);
    --cardShadow: 0 2px 10px 0 rgba(207,207,207,0.5);
    --cardBorder: 1px solid #D9DADB;

    // Form
    --inputTextError: #BB2828;
    --inputBorderError: #CB051C;

    // History
    --yearTxtMobile:        #999999;
    --yearTxtDesktop:       #dedede;
    --filterBg:             #f5f5f5;

    // Calendar Events
    --calendarGray:         #F6F7F9;
    --calendarEventsListBg: #F6F7F9;

    // Country Finder
    --bgHeroCountry: #0B0D29;

    // Footer
    --footerBg: #0f0f0f;
    --footerNavBg: #1f1f1f;

    // Others

    // BRAND COLORS
    --concardis:            #00BEF0;
    --ratepay:              #ED0021;
    --paytrail:             #E42E87;
    --dankort:              #DC0D19;
    --dotpay:               #9F191D;
    --pep:                  #F39325;
    --przelewy:             #D13239;
    --poplapay:             #29B58F;
    --poplatek:             #229561;
    --ccv:                  #009FE3;
    --storebox:             #63C3FF;
    --signaturGruppen:      #009DE0;
    --payzone:              #B71F6C;
    --edigard:              #32D982;
    --checkout:             #2C2E83;
    --ecard:                #04385F;
    --siapay:               #AF0834;
}

//
//  COLORS VARS
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Primary
$azure: var(--azure);
$blue: var(--blue);
$red: var(--red);
$green: var(--green);

// Secondary
$darkBlue: var(--darkBlue);
$black: var(--black);
$blackGray: var(--blackGray);
$darkerGray: var(--darkerGray);
$darkGray: var(--darkGray);
$gray: var(--gray);
$lightGray: var(--lightGray);
$lighterGray: var(--lighterGray);
$grayWhite: var(--grayWhite);
$dirtyWhite: var(--dirtyWhite);
$white: var(--white);

// Commons
$shadow: var(--shadow);
$hoverBlue: $darkBlue;
$hoverWhite: $dirtyWhite;

// Text
$txt: var(--txt);               // TEMP
$txtAlt: var(--txtAlt);         // TEMP
$txtBright: var(--txtBright);   // TEMP

// Background
$pageBg: var(--pageBg);
//$bgDark: var(--bgDark);

// Header
$headerBg: var(--headerBg);
$headerBgActive: var(--headerBgHover);
$headerBgAlt: var(--headerBgAlt);
$headerBgAltActive: var(--headerBgAltHover);
$navItemActive: var(--navItemActive);

// Buttons
$buttonPrimaryBg: var(--buttonPrimaryBg);
$buttonPrimaryBgHover: var(--buttonPrimaryBgHover);
$buttonPrimaryBgDisabled: var(--buttonPrimaryBgDisabled);
$buttonPrimaryTxt: var(--buttonPrimaryTxt);
$buttonPrimaryBorder: var(--buttonPrimaryBorder);
$buttonPrimaryBgAlt: var(--buttonPrimaryBgAlt);
$buttonPrimaryBgAltHover: var(--buttonPrimaryBgAltHover);
$buttonPrimaryBgAltDisabled: var(--buttonPrimaryBgAltDisabled);
$buttonPrimaryTxtAlt: var(--buttonPrimaryTxtAlt);
$buttonPrimaryTxtAltHover: var(--buttonPrimaryTxtAltHover);
$buttonPrimaryBorderAlt: var(--buttonPrimaryBorderAlt);
$buttonSecondaryBg: var(--buttonSecondaryBg);
$buttonSecondaryBgHover: var(--buttonSecondaryBgHover);
$buttonSecondaryBgDisabled: var(--buttonSecondaryBgDisabled);
$buttonSecondaryBorder: var(--buttonSecondaryBorder);
$buttonSecondaryTxt: var(--buttonSecondaryTxt);
$buttonSecondaryBgAlt: var(--buttonSecondaryBgAlt);
$buttonSecondaryBgAltHover: var(--buttonSecondaryBgAltHover);
$buttonSecondaryBgAltDisabled: var(--buttonSecondaryBgAltDisabled);
$buttonSecondaryBorderAlt: var(--buttonSecondaryBorderAlt);
$buttonSecondaryTxtAlt: var(--buttonSecondaryTxtAlt);
$buttonBigShadow: var(--cardShadow);

//Card
$card100GradientBg: var(--blackGradient);
$cardSharePanelBg: var(--cardSharePanelBg);
$cardSharePanelBgAlt: var(--cardSharePanelBgAlt);
$cardShadow: var(--cardShadow);
$cardBorder: var(--cardBorder);

// Form
$inputTextError: var(--inputTextError);
$inputBorderError: var(--inputBorderError);

//Disclaimer
$widget-disclaimer-text: var(--widgetDisclaimerText);

//Brand
$concardis: var(--concardis);
$ratepay:   var(--ratepay);
$paytrail:  var(--paytrail);
$dankort:   var(--dankort);
$dotpay:    var(--dotpay);
$pep:       var(--pep);
$przelewy:  var(--przelewy);
$poplapay:  var(--poplapay);
$poplatek:  var(--poplatek);
$ccv:       var(--ccv);
$storebox:  var(--storebox);
$signaturGruppen: var(--signaturGruppen);
$payzone:   var(--payzone);
$edigard:   var(--edigard);
$checkout:  var(--checkout);
$ecard:     var(--ecard);
$siapay:       var(--siapay);

// Hystory
$yearTxtMobile: var(--yearTxtMobile);
$yearTxtDesktop: var(--yearTxtDesktop);
$filterBg: var(--filterBg);

// Calendar Events
$calendarGray: var(--calendarGray);
$calendarEventsListBg: var(--calendarEventsListBg);

// Country Finder
$bgHeroCountry: var(--bgHeroCountry);

// Footer
$footerBg: var(--footerBg);
$footerNavBg: var(--footerNavBg);