@use 'sass:math';

@mixin scrolledBg {
    background: $header-bg-active;

    @include ie-only() {
        background: $header-bg-alt-active;
    }

    @supports not (backdrop-filter: #{$ui-blur}) {
        background: $header-bg-alt-active;
    }
}

@mixin underline($spacing: false) {
    span {
        box-shadow: 0 2px 0 0 $white;
        
        @if ($spacing) {
            padding: 4px 0;
        } @else {
            padding: 0;
        }
    }
}

@mixin buttonArrow() {
    content: $icon-caret_right;
    display: inline-block;
    font-family: '#{$nexi-iconfont-family}';
    font-size: rem(11px);
    vertical-align: middle;
    position: absolute;
    right: 0;
    width: auto;
    height: auto;
    transition: right .2s ease-out;
}

@mixin staggerAnim() {
    animation-name: slideInLeft;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;
    animation-delay: calc(var(--header-flyout-link-anim-delay) * var(--flyout-anim-index, 0));
}

%firstLevelMenuItem {
    display: flex;
    align-items: center;

    @include respond-above(lg) {
        //height: 100%;
        //padding: 0 rem(20px);
    }
}



.base-header {
    $this: &;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    //top: -1px;   // mobile safari position fixed
    top: 0;
    right: 0;
    left: 0;
    z-index: setZ(header);
    transition: top 0.3s ease-out;

    @mixin flyoutOpening() {
        max-height: 100vh;

        animation-name: flyoutDown;
        animation-duration: .3s;
        animation-timing-function: ease-out;
        
        #{$this}__flyout-items {
            opacity: 1;
        }
    }

    @mixin flyoutClosing() {
        animation-name: flyoutUp;
        animation-duration: .4s;
        animation-timing-function: ease-in;
    }

    &.nav--opened {
        bottom: 0;
        backdrop-filter: #{$ui-blur}
    }

    &.scrolled-hidden:not(.nav--opened) {
        top: - 184px;
    }

    &.with-stickybar {
        top: 0px;

        @include respond-below(lg) {
            top: 0px;
            position: fixed;
        }

        &.scrolled-hidden:not(.nav--opened) {
            top: auto;

            .base-header__nav {
                display: none;
            }
        }
    }

    &.no-sticky{
        top:0px;

    }

    &.no-sticky.scrolled-hidden:not(.nav--opened) {
        top: - 112px;
        @include respond-above(lg){
        top: - 72px;}
        
    }
    &--sticky-menu {
        @include respond-above(lg) {

            &.scrolled-hidden{
                top: - $header-height;
            }
        }
    }
    &.no-sticky.scrolled .sticky-bar{
        top: - 112px;
        position: fixed;
        @include respond-above(lg){
        top: - 72px;}
        
    }

    &__bg {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: calc(100% + 1px);
        //height: -webkit-fill-available;
        z-index: -1;
        //transition: opacity .3s ease-out;
        transition: background-color .3s ease-out;
        backdrop-filter: blur(18px);

        .base-detail-page &,
        .base-news-page & {
            background: $headerBgActive;
        }

        #{$this}.scrolled &,
        .nav--opened & {
            @include scrolledBg();
        }

        .nav--opened & {
            backdrop-filter: $ui-blur;
        }

        @include respond-above(lg) {
            top: 0;
            height: 100%;
            background: none;
            backdrop-filter: $ui-blur;

            #{$this}__nav.flyout--active + & {
                background: none;
                transition: background-color .1s ease-out;
            }

            #{$this}__nav.flyout--closing + & {
                @include scrolledBg();
                transition: background-color .4s ease-out .2s;
            }
        }

        #{$wcmmode} & {
            background: $header-bg-active;
        }
    }

    &__nav {
        $this-nav: &;
        //@include percentage-container();

        position: relative;
        width: 100%;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        height: $header-height;
        //overflow: hidden;
        justify-content: space-between;
        align-items: center;
        padding: $header-mobile-padding;

        &.no-anim {

            #{$this}__flyout--vertical {
                animation-name: none !important;
                //animation-duration: 0 !important;
            }
        }

        // DESKTOP EVENT
        &.flyout--active {

            @include respond-above(lg) {

                ul > li.flyout--opened {

                    #{$this}__flyout--vertical {
                        @include flyoutOpening();
                    }

                    #{$this}__flyout--horizontal {
                        li {
                            @include staggerAnim();
                        }
                    }
                }

                ul > li.flyout--closing {
                    
                    #{$this}__flyout--vertical {
                        @include flyoutClosing();
                    }
                }
            }
        }
        
        // MOBILE EVENT
        .nav--opened & {

            @include respond-below(lg) {
                height: 100vh;
                height: -webkit-fill-available;
                flex-direction: column;
                padding: 0;
                transition: left .3s ease-out;

                #{$this}__menu-btn {
                
                    .close {
                        display: block;
                    }
        
                    .open {
                        display: none;
                    }
                }
    
                #{$this}__top {
                    position: fixed;
                    padding: $header-mobile-padding;
                }
    
                #{$this-nav}-items {
                    //display: flex;
                    width: 100%;
                    //height: 100%;
                    height: calc(100vh - #{$header-mobile-tools-height} - #{$header-height});
                    padding: $header-mobile-padding;
                    //padding-top: 24px;
                    padding-bottom: 24px;
                    margin-top: $header-height;
                    margin-bottom: $header-mobile-tools-height;
                    flex-direction: column;
                    //overflow-y: auto;
                    overflow: auto;
                    opacity: 1;
                }
    
                #{$this-nav}-tools {
                    display: flex;
                    justify-content: space-between;
                    position: fixed;
                    bottom: -1px;   // iOS Safari workaround
                    width: 99.99%;  // iOS Safari workaround
                    min-height: $header-mobile-tools-height;
                    height: $header-mobile-tools-height;
                    padding: $header-mobile-padding;
                    background: $header-mobile-tools-bg;
                    opacity: 1;
                }
            }

            @include respond-below(md, landscape) {
                height: -webkit-fill-available;
                
                #{$this-nav}-items {
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: #{math.div($header-mobile-tools-height, 2)};
                }

                #{$this-nav}-tools {
                    min-height: #{math.div($header-mobile-tools-height, 2)};
                    height: #{math.div($header-mobile-tools-height, 2)};
                }
            }
        }

        @include respond-above(lg) {
            position: static;
            flex-wrap: nowrap;
            justify-content: flex-start;
            padding: 0 rem(90px);
        }
        @include mouse-only() {
 
            &:hover #{$this}__bg {
                @include scrolledBg();
            }
        }
    }

    &__top {
        display: flex;
        min-height: $header-height;
        height: $header-height;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        @include respond-above(lg) {
            min-height: 0;
            height: auto;
            width: auto;
            padding-right: rem(40px);
        }
    }

    &__logo {
        max-height: 21px;

        a {
            display: inline-block;

            @include mouse-only() {
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }

        span {
            opacity: 0;
            position: absolute;
        }

        img {
            max-width: 100%;
            max-height: 21px;
            height: auto;
        }

        @include respond-above(lg) {
            //@include make-col(3);
            min-width: 72px;
        }
    }

    &__menu-btn,
    &__menu-close-btn {
        padding: rem(20px);
        padding-right: 0;
    }

    &__menu-btn {

        .close {
            display: none;
        }
        
        @include respond-above(lg) {
            display: none;
        }
    }

    &__menu-close-btn {
        
        @include respond-above(lg) {
            padding: rem(15px);
            padding-right: 0;
        }
    }

    &__nav-items {
        //display: none;
        display: flex;
        height: 0;
        padding: 0;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        border: none;
        opacity: 0;
        transition: opacity .3s ease-out;
        touch-action: none; // iOS Safari scroll workaround

        @include respond-above(lg) {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
            opacity: 1;
            //overflow: auto;
        }
    }

    &__nav-item {
        @extend %firstLevelMenuItem;

        &.has-submenu {

            > #{$this}__nav-button::after {
                @include buttonArrow();

                top: 50%;
                right: 5px;
                transform: translateY(-50%);
            }

            @include respond-above(lg) {

                > #{$this}__nav-button::after {
                    display: none;
                }

                @include mouse-only() {
///////////////////////////////////////////////////////////////
                    //#{$this}__nav-button,
///////////////////////////////////////////////////////////////
                    /*> #{$this}__nav-button:hover {
                    //#{$this}__nav-button:focus-within {
    
                        & + #{$this}__flyout {
                            @include hoverOpen();
                        }
                    }*/
                }
            }
        }
        
        &.active {

            > #{$this}__nav-link {
                pointer-events: none;
            }

            > #{$this}__nav-button,
            > #{$this}__nav-link {
                @include underline();

                @include respond-above(lg) {
                    @include underline(true);
                }
            }
        }

        @include respond-above(lg) {
            
            &:last-child {
                #{$this}__nav-button,
                #{$this}__nav-link {
                    padding-right: 0;
                }
            }
        }
    }

    &__nav-button,
    &__nav-link {
        @include karbonMedium();

        display: flex;
        width: 100%;
        height: rem(72px);
        position: relative;
        align-items: center;
        font-size: rem(20px);
        line-height: rem(20px);
        letter-spacing: 1px;
        text-transform: uppercase;

        @include respond-above(lg) {
            width: auto;
            height: 100%;
            padding: 0 rem(20px);
            font-size: rem(16px);
            line-height: rem(16px);
            letter-spacing: 1px;
            z-index: 1;
        }

        &.active {
            @include underline(true);
        }

        @include mouse-only() {
            &:hover {
                @include underline(true);
            }
        }

        &:focus,
        &:active {
            outline: none;
        }
    }

    &__flyout {
        display: none;
        position: absolute;
        top: 0;
        height: 100vh;
        height: -webkit-fill-available;
        transition: left .3s ease-out;

        @include respond-above(lg) {
            display: flex;
            top: 0;
            left: 0;
            height: auto;
            padding: $header-mobile-padding;    // ?????????
            padding-top: 24px;
            padding-bottom: 24px;
            overflow: hidden;
        }

        &--vertical {
            left: 100%;
            width: 100%;

            @include respond-above(lg) {
                @include scrolledBg();
                //backdrop-filter: $ui-blur;

                left: 0;
                //height: 0;
                //max-height: $header-height;
                max-height: 0;
                width: 100%;
                z-index: 0;
                padding: 0;
                //transition: opacity .3s ease-out,
                //            max-height .4s ease-out;

                /*&:hover {
                    @include hoverOpen();
                }*/

                #{$this}__flyout-container > ul > li {
                    display: inline-block;
                    width: 25%;

                    .recommendation-active & {
                        width: 35%;
                    }
                }

                .flyout--opened #{$this}__flyout-container > ul {
                    margin-top: $header-height;
                }
            }
        }

        &--horizontal {
            left: 100%;
            //height: 100%;
            width: 100%;
            flex-direction: column;

            ul {

                #{$this}__flyout-item {
                    display: inline-block;
                    height: auto;
                    width: 100%;
                }

                #{$this}__flyout-link {
                    font-size: rem(18px);
                    line-height: rem(18px);
                    letter-spacing: normal;
                    padding: rem(3px) 0;
                }
            }

            @include respond-above(lg) {
                display: none;
                top: 0;
                left: 24%;
                height: 100%;
                width: 100%;
                max-width: 75%;
                padding: 0 rem(10px) 0 rem(70px);
                z-index: 0;

                /*&:hover {
                    display: flex;
    
                    #{$this}__flyout-items {
                        opacity: 1;
                    }
                }*/

                .recommendation-active & {
                    left: 34%;
                    max-width: 66%;
                }

                ul {
                    flex-direction: column;
                    flex-wrap: wrap;
                    margin-top: 0;

                    #{$this}__flyout-item {
                        width: auto;
                    }
                }

                #{$this}__flyout-link {
                    font-size: rem(16px);
                    line-height: rem(20px);
                }

                #{$this}__flyout-tools {
                    display: none;
                }
            }
        }
    }

    &__flyout-container {
        width: 100%;
        height: 100%;

        @include respond-above(lg) {
            backdrop-filter: $ui-blur;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0 5vw;
        }

        @include respond-above(xl) {
            padding: 0 10vw;
        }
    }

    &__flyout-items {
        //@include percentage-container();

        flex-direction: column;
        //position: relative;
        height: calc(100% - #{$header-height});
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: $header-height;
        padding: $header-mobile-padding;
        padding-top: 24px;
        padding-bottom: 24px;

        @include respond-above(lg) {
            display: flex;
            position: relative;
            opacity: 0;
            padding: rem(50px) 0;
            height: 100%;
            width: 100%;
            transition: opacity .3s ease-out .2s;

            &.highlight {

                > li > #{$this}__flyout-link:not(.highlight),
                > li > #{$this}__flyout-button:not(.highlight) {
                    opacity: .4;
                }
            }

            @include ie-only(){
                height: auto;
                //min-height: 100%;
            }
        }

        @include respond-below(lg) {

            @media (orientation: landscape) {
                height: calc(100% - #{$header-height} - 15px);
                padding-top: 0;
                padding-bottom: 0;
                //margin-bottom: #{math.div($header-mobile-tools-height, 2)};
            }
        }
    }

    &__flyout-recommendation {
        display: none;
        width: 260px;
        min-width: 260px;
        margin-top: $header-height;
        margin-right: 9%;
        padding: rem(50px) 0;
        font-size: rem(14px);
        line-height: rem(18px);

        @include respond-above(lg) {
            display: block;
        }
    }

    &__recommendation-label {
        @include karbonMedium();

        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 8px 0 15px;
    }

    &__recommendation-img-link {
        display: block;
        width: 100%;

        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: auto;
        }
    }

    &__recommendation-title {
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -0.5px;
        padding-top: 10px;
    }

    &__recommendation-img-link + &__recommendation-title {
        padding-top: 16px;
    }
    
    &__recommendation-desc {
        color: $white;
        padding: 15px 0 30px;
    }

    &__recommendation-link {
        margin-top: 8px;
        padding: 2px 0 2px;
        font-size: rem(14px);
    }

    &__flyout-tools {
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        width: 100%;
        height: $header-height;
        padding: $header-mobile-padding;
        z-index: 100;

        @include respond-above(lg) {
            position: absolute;
            right: 5vw;
            top: calc(#{$header-height} + 30px);
            height: auto;
            width: auto;
            padding: 0;
        }

        @include respond-above(xl) {
            right: 10vw;
        }
    }

    &__back-btn {
        @include karbonMedium();

        position: relative;
        padding: 20px;
        padding-left: 30px;
        font-size: rem(16px);
        text-transform: uppercase;
        letter-spacing: 1px;

        &::before {
            @include nexiIcon();
            
            content: $icon-arrow_right;
            position: absolute;
            top: 50%;
            left: -2px;
            font-size: rem(18px);
            transform: translate(0, -50%) rotate(180deg);
        }

        @include respond-above(lg) {
            display: none;
        }  
    }

    &__flyout-item {
        @extend %firstLevelMenuItem;

        padding: rem(8px) 0;

        &.active {
            > #{$this}__flyout-link,
            > #{$this}__flyout-button {
                @include karbonMedium();
            }
        }

        &.active:not(.has-submenu) {
            > #{$this}__flyout-link span {
                pointer-events: none;
            }
        }
    }

    &__nav-item,
    &__flyout-item {

        &.flyout--opened {
                
            > #{$this}__flyout {
                display: flex;
            }
        }
    }

    &__flyout-link,
    &__flyout-button {
        @include karbonRegular();

        //display: block;
        font-size: rem(20px);
        line-height: rem(20px);
        text-transform: none;
        letter-spacing: normal;
        padding: rem(8px) 0;
        padding-right: rem(35px);
        height: 100%;
        width: 100%;

        @include mouse-only() {
            
            &:hover span {
                padding: 0;
                box-shadow: none;
            }

            #{$this}__flyout-item:not(.active) &:hover {
                @include karbonMedium();
                letter-spacing: -0.33px; // Workaround for multiline text
            }

            &:not(span):hover {
                
                span {
                    text-decoration: underline;
                }
            }
        }

        @include respond-above(lg) {
            font-size: rem(24px);
            line-height: rem(28px);
            letter-spacing: normal;
            padding: rem(8px) 0;
            padding-right: rem(35px);
            transition: opacity .4s ease-out;
        }
    }

    &__flyout-link {

        @include respond-above(lg) {
            display: inline-flex;
            width: auto;
        }
    }

    &__nav-button,
    &__flyout-button {

        @include respond-above(lg) {
            cursor: default;
        }
    }

    &__flyout-button {

        &::after {
            @include buttonArrow();

            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            font-size: rem(11px);
            
            @include respond-above(lg) {
                font-size: rem(16px);
            }
        }

        #{$this}__flyout-item:not(.active) &.highlight {
            letter-spacing: -0.33px; // Workaround for multiline text
        }

        &.highlight {
            @include karbonMedium();

            &::after {
                right: 0;
            }
        }
        
        @include mouse-only() {
            
            &:hover {

                &::after {
                    right: 0;
                }
            }
        }
    }

    &__flyout-recommentation {
        display: none;

        @include respond-above(lg) {
            display: flex;
        }
    }

    &__nav-tools {
        //display: none;
        display: flex;
        align-items: center;
        height: 0;
        opacity: 0;
        transition: opacity .3s ease-out;
        overflow: hidden;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include respond-above(lg) {
            //display: flex;
            padding-left: rem(20px);
            height: 100%;
            overflow: visible;
            opacity: 1;
            z-index: 1;
        }
    }

    &__login-cta {
        @extend %btn;
        @include karbonMedium();

        padding-left: 0;
        font-size: rem(14px);
        text-transform: uppercase;
        color: $white;
        border-radius: 30px;

        .icon-user {
            font-size: rem(16px);
            margin-right: 10px;
        }

        @include respond-above(lg) {
            font-size: rem(16px);
            text-transform: capitalize;
            letter-spacing: 1px;
            padding: 11px 14px;
            //margin-right: rem(28px);
            border: 1px solid $white;
            white-space: nowrap;
        }

        @include mouse-only() {
            &:hover {
                background-color: rgba(255,255,255,0.2);
            }
        }
    }

    &__country-finder-btn {
        display: flex;
        align-items: center;

        &.active {

            @include respond-above(lg) {
                background-color: rgba(255,255,255,0.2);
            }
        }

        .icon-globe_closed {
            width: 24px !important;
            height: 24px !important;
        }

        span {
            margin-left: 16px;

            @include respond-above(lg) {
                opacity: 0;
                position: absolute;
            }
        }

        @include mouse-only() {
            &:hover {
                background-color: rgba(255,255,255,0.2);
            }
        }

        @include respond-above(lg) {
            //margin-left: rem(28px);
            padding: rem(5px);
            //padding-right: rem(5px);
            border-radius: 50%;

            @include ie-only(){
                flex-basis: 36px;
            }
        }
    }

    @include respond-above(lg) {
        top: 0;
    }

    #{$wcmmode} & {
        position: absolute;
        max-height: $header-height;
    }

    &.base-header.with-stickybar { 
          
        
        #{$this}__flyout-items{
            margin-top: calc(#{$header-height} + 72px);
        }

        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 72px);
        }

        #{$this}__flyout-recommendation{
            margin-top: calc(#{$header-height} + 72px);
        }
        #{$this}__menu-close-btn{
            margin-top:72px;
        }

        @include respond-below(lg) {
        
           
         
        #{$this}__back-btn{
            margin-top: calc(#{$header-height} + 40px);
        }
        #{$this}__menu-close-btn{
            margin-top: calc(#{$header-height} + 40px);
        }
        #{$this}__flyout-items.second-level{
            margin-top: calc(#{$header-height} - 10px);
            padding-bottom: 184px;
        }
        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 10px);
            padding-bottom: 184px;
        }
        #{$this}__back-btn::before{
            margin-top: 8px;
        }
        #{$this}__nav-items {
            margin-bottom: 220px;
        }
    }
}
&.base-header.no-sticky { 
          
        
    #{$this}__flyout-items{
        margin-top: calc(#{$header-height} + 72px);
    }

    #{$this}__flyout-items.third-level{
        margin-top: calc(#{$header-height} - 72px);
    }

    #{$this}__flyout-recommendation{
        margin-top: calc(#{$header-height} + 72px);
    }
    #{$this}__menu-close-btn{
        margin-top:72px;
    }

    @include respond-below(lg) {
    
       
     
    #{$this}__back-btn{
        margin-top: calc(#{$header-height} + 40px);
    }
    #{$this}__menu-close-btn{
        margin-top: calc(#{$header-height} + 40px);
    }
    #{$this}__flyout-items.second-level{
        margin-top: calc(#{$header-height} - 10px);
        padding-bottom: 184px;
    }
    #{$this}__flyout-items.third-level{
        margin-top: calc(#{$header-height} - 10px);
        padding-bottom: 184px;
    }
    #{$this}__back-btn::before{
        margin-top: 8px;
    }
    #{$this}__nav-items {
        margin-bottom: 220px;
    }
}
}

@keyframes slideInLeft {
    from {
        transform: translate(-50%,0);
        opacity: 0;
    }
    to {
        transform: translate(0,0);
        opacity: 1;
    }
}

@keyframes flyoutDown {
    0% {
        max-height: 0;
        //padding-top: 0;
    }
    100% {
        //top: $header-height;
        //padding-top: $header-height;
        max-height: 100vh;
    }
}

@keyframes flyoutUp {
    0% {
        max-height: 70vh;
    }
    100% {
        max-height: $header-height;
    }
}
}
