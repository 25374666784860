@mixin btn-tab-slide {
    font-size: rem(16px);
    line-height: rem(14px);
    letter-spacing: -0.5px;
    color: $black;
    flex: 0 1 100%;
    padding: 0 12px;
    overflow: hidden;
    text-align: center;
    transition: all .3s cubic-bezier(.09,.72,.76,1.01);
    
    @include respond-above(lg) {
        font-size: rem(24px);
        line-height: rem(22px);
        padding: 0 16px;
    }
    
    &.active {
        @include karbonMedium;
        background: $white;
        border-radius: rem(40px);
        color: $blue;
        box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
    }

    @include mouse-only() {
        &:hover {
            // font-family: KarbonMedium;
            background: $white;
            border-radius: rem(40px);
            color: $blue;
            box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        }
    }

    &:hover {
        @include karbonMedium();
    }
}

@mixin btn-tab-slide-blue {
    font-size: rem(12px);
    line-height: rem(16px);
    letter-spacing: 1.5px;
    border-top: var(--tabBorder);
    border-bottom: var(--tabBorder);
    border-right: var(--tabBorder);
    background: $white;
    text-align: center;
    text-transform: uppercase;
    transition: all .3s cubic-bezier(.09,.72,.76,1.01);

    &.active {
        @include karbonMedium;

        border-color: var(--tabBorderActive);
        background: $blue;
        color: $white;
        border-radius: 0;
        box-shadow: none;
    }

    @include mouse-only() {
        &:hover {
            // font-family: KarbonMedium;
            border-color: var(--tabBorderActive);
            background: $blue;
            color: $white;
            border-radius: 0;
        }
    }

    &:hover {
        @include karbonMedium();
    }

    @include respond-above(lg) {
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: 2px;
        padding: 0 10px;
    }
}

@mixin btn-tab-slide-blue-border {
    font-size: rem(16px);
    line-height: rem(18px);
    letter-spacing: -0.5px;
    color: $black;
    flex: 0 1 100%;
    padding: 0 4px;
    text-align: center;
    transition: none;

    @include respond-above(lg) {
        font-size: rem(24px);
    }

    &.active {
        @include karbonMedium();
        background: $white;
        border-radius: rem(40px);
        color: $blue;
        border: 2px solid $blue;
        box-shadow: none;
    }

    @include mouse-only() {
        &:hover {
            background: $white;
            border-radius: rem(40px);
            color: $blue;
            box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        }
    }
}


$blue-skin : '.widget-tab-slide--blue';
$blue-border-skin : '.widget-tab-slide--blue-border';
$align-center: '.tab-align--center';
$width100: '.column__width--100';
$width50: '.column__width--50';


.widget-tab-slide {
    $this-tab: &;

    background-color: $white;

    &.p-t-def {
        padding-top: rem(40px);

        @include respond-above(lg) {
            padding-top: rem(64px);
        }
    }

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(64px);
        }
    }

    &__tab-group {
        @include container();
        @include content-container();
        
        @include respond-above(lg) {
            display: flex;

            #{$align-center} & { 
                .swiper-wrapper {
                    justify-content: center;
                }
            }

            .swiper-wrapper {
                flex-wrap: wrap;
            }

        }
        
        #{$blue-skin} & {
            height: 40px;
        }
    }

    .swiper {
        overflow: visible;
    }

    &__tab-item {
        display: inline-flex;
        background-color: var(--tabGroupBGDefault);
        height: rem(56px);
        flex: 0 1 auto;
        padding: 4px;
        min-width: rem(154px);

        &:first-child {
            border-top-left-radius: rem(40px);
            border-bottom-left-radius: rem(40px);
        }

        &:last-child {
            border-top-right-radius: rem(40px);
            border-bottom-right-radius: rem(40px);
        }

        @include respond-above(lg) {
            &:nth-child(5n + 1) {
                border-top-left-radius: rem(40px);
                border-bottom-left-radius: rem(40px);
            }

            &:nth-child(5n) {
                border-top-right-radius: rem(40px);
                border-bottom-right-radius: rem(40px);
            }
        }

        #{$blue-skin} & {
            background-color: transparent;
            padding: 0;
            height: 40px;

            &:first-child {
                border-radius: 0;

                #{$this-tab}__btn {
                    border-top-left-radius: rem(8px);
                    border-bottom-left-radius: rem(8px);
                    border-left: var(--tabBorder);

                    &.active {
                        border-left: $blue;
                    }
                }
            }

            &:last-child {
                border-radius: 0;

                #{$this-tab}__btn {
                    border-top-right-radius: rem(8px);
                    border-bottom-right-radius: rem(8px);
                }
            }

            @include respond-above(lg) {
                &:nth-child(5n + 1) {
                    border-radius: 0;

                    #{$this-tab}__btn {
                        border-top-left-radius: rem(8px);
                        border-bottom-left-radius: rem(8px);
                        border-left: var(--tabBorder);

                        &.active {
                            border-left: $blue;
                        }
                    }
                }

                &:nth-child(5n) {
                    border-radius: 0;

                    #{$this-tab}__btn {
                        border-top-right-radius: rem(8px);
                        border-bottom-right-radius: rem(8px);
                    }
                }
            }
        }

        #{$blue-border-skin} & {
            background-color: $white;
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-top-color: rgba(0, 0, 0, 0.10);
            border-bottom-color: rgba(0, 0, 0, 0.10);

            &:first-child {
                border-left: 1px solid;
                border-left-color: rgba(0, 0, 0, 0.10);
            }
            &:last-child {
                border-right: 1px solid;
                border-right-color: rgba(0, 0, 0, 0.10);
            }

            @include respond-above(lg) {
                &:nth-child(5n + 1) {
                    border-left: 1px solid;
                    border-left-color: rgba(0, 0, 0, 0.10);
                }

                &:nth-child(5n) {
                    border-right: 1px solid;
                    border-right-color: rgba(0, 0, 0, 0.10);
                }
            }
        }

        .swiper-container-initialized & {
            flex: 1 0 41.85%;
        }

        @include respond-above(lg) {
            height: rem(64px);
            flex: 0 0 20%;

            &:first-child:nth-last-child(-n + 3),
            &:first-child:nth-last-child(-n + 3) ~ & {
                flex: 0 1 28.597%;
            }

            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ & {
                flex: 0 0 25%;
            }
        }
    }

    &__btn {
        @include btn-tab-slide();

        #{$blue-skin} & {
            @include btn-tab-slide-blue();
        }

        #{$blue-border-skin} & {
            @include btn-tab-slide-blue-border();
        }
    }

    &__tab-pane {
        display: none;
        color: $black;
        padding-top: rem(18px);
        padding-bottom: 0;
        
        &.active {
            display: block;
        }

        @include respond-above(lg) {
            padding-top: rem(24px);

            #{$blue-skin} & {
                padding-top: rem(24px);
            }
        }

        #{$wcmmode} & {
            display: block;
        }
    }

    &__row {
        @include make-row();
    }

    &__column {
        @include make-col-ready();

        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            #{$width100} & {
                @include make-col(12);
            }

            #{$width50} & {
                @include make-col(6);
            }
        }
    }
}