
.countryMap {
    &__country {
        
        cursor: pointer;
        stroke:#0b0d29;
        fill:#1f2375;
        transition: fill .3s cubic-bezier(.09,.72,.76,1.01);

        &:hover {
            fill: #2D32AA;
        }
    }

    &__country-disabled {
        fill:#151950;
        stroke:#0b0d29;
    }

    &__circle {
        fill:$white;
        stroke:$white;
        stroke-miterlimit:10;
        stroke-width:0.25px;
    }
}
 
.svgMap-wrapper,
.svgMap-container {
    position: relative;
    width: 100%;
    height:100%;
}

.svgMap-map-wrapper {
    position: relative;
    width: 100%;
    max-height: 556px;
    // padding-top: 50%;
    overflow: hidden;

    * {
        box-sizing: border-box;
    }

    :focus:not(:focus-visible) {
        outline: 0;
    }

    @include respond-above(md) {
        max-height: 100%;
    }

    .svgMap-map-image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;

        @include respond-above(lg) {
            top: -50px;
        }
    }

    .svgMap-country {
        stroke-width: 1;
        stroke-linejoin: round;
        vector-effect: non-scaling-stroke;
        transition: fill 250ms, stroke 250ms;

        &[data-link] {
            cursor: pointer;
        }

        &.svgMap-active {
            stroke: #333;
            stroke-width: 1.5;
        }

        @include respond-below(lg) {
            pointer-events: none;
            cursor: auto;
        }
    }
}

.svgMap-tooltip {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1000;
    background: #fff;
    transform: translate(-50%, -100%);
    border-bottom: 1px solid #000;
    display: none;
    pointer-events: none;
    min-width: 60px;
    border-top-left-radius:0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &.svgMap-active {
        display: block;
    }

    .svgMap-tooltip-content-container {
        position: relative;
        padding: 7px 34px 8px 34px;
    }

    .svgMap-tooltip-title {
        @include karbonMedium();

        text-transform: uppercase;
        font-size: rem(14px);
        line-height: rem(18px);
        letter-spacing: 2px;
        color: $blue;
    }
}

.svg-pan-zoom_viewport {
    #{$wcmmode} & {
      transform: matrix(3.1128, 0, 0, 3.1128, -111.6, -240) !important;
    }
}