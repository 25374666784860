%skeleton-animation {

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
        animation: loading 750ms infinite;
    }
}

.elem-calendar-event {
    position: relative;
    padding: 24px;
    //padding-bottom: 0;
    margin-bottom: 16px;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;

    #{$edit_mode} .event-card & {
        background-color: #f6f6f6;
    }

    @include respond-above(sm) {
        padding: 24px;
    }

    &__border {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        background-color: $lightGray;
        pointer-events: none;

        .skeleton-event & {
            display: none;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        //padding-bottom: 15px;
        font-size: rem(10px);
        line-height: rem(12px);
        color: $darkGray;
        letter-spacing: 2px;

        .skeleton-event & {
            line-height: 0;
        }

        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: rem(18px);
        }
    }

    &__header-text {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        padding-left: 15px;

        .skeleton-event & {
            @extend %skeleton-animation;

            background-color: $dirtyWhite;
            border-radius: 4px;
            width: 50%;
            height: 18px;
            margin-right: 25px;
            margin-bottom: 18px;
            overflow: hidden;
        }

        .skeleton-event.style1 & {
            width: 70%;
        }

        .skeleton-event.style2 & {
            width: 30%;
        }

        .skeleton-event.style3 & {
            width: 42%;
        }

        @include respond-above(lg) {
            padding-left: 20px;
        }
    }

    &__category {
        @extend %category-bullet;

        position: absolute;
        top: 2px;
        left: 0;

        .skeleton-event & {
            display: none;
        }

        @include respond-above(lg) {
            top: 5px;
            left: 2px;
        }
    }

    &__location {
        
        .skeleton-event & {
            display: none;
        }
    }

    &__date {
        display: inline-block;
        margin-right: 50px;
        margin-bottom: 10px;

        .skeleton-event & {
            display: none;
        }
    }

    &__hours {
        position: relative;
        display: inline-block;
        padding-left: 15px;
        margin-bottom: 8px;
        

        em {
            position: absolute;
            top: 0;
            left: 0;
            font-size: rem(12px);
            color: #C0C0C0;

            .skeleton-event & {
                display: none;
            }

            @include respond-above(lg) {
                font-size: rem(17px);
            }
        }

        .skeleton-event & {
            @extend %skeleton-animation;

            background-color: $dirtyWhite;
            border-radius: 4px;
            width: 20%;
            height: 18px;
            margin-bottom: 18px;
            overflow: hidden;
        }

        .skeleton-event.style1 & {
            width: 30%;
        }

        .skeleton-event.style2 & {
            width: 12%;
        }

        .skeleton-event.style3 & {
            width: 16%;
        }

        @include respond-above(md) {
            white-space: nowrap;
        }

        @include respond-above(lg) {
            padding-left: 20px;
        }
    }

    &__title {
        //padding-bottom: 20px;
        font-size: rem(20px);
        line-height: rem(26px);
        color: $black;

        .skeleton-event & {
            @extend %skeleton-animation;

            position: relative;
            background-color: $dirtyWhite;
            border-radius: 4px;
            width: 80%;
            height: 28px;
            overflow: hidden;
        }

        .skeleton-event.style1 & {
            width: 90%;
        }

        .skeleton-event.style2 & {
            width: 58%;
        }

        .skeleton-event.style3 & {
            width: 77%;
        }

        @include respond-above(lg) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__description {
        padding-top: 20px;
        font-size: rem(16px);
        line-height: rem(22px);
        color: $darkGray;

        .skeleton-event & {
            display: none;
        }

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: rem(24px);
        }
    }

    &__cta-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 18px;
    }

    &__cta {
        width: 100%;
        //margin-bottom: 20px;
        white-space: nowrap;
        max-width: none;

        .skeleton-event & {
            @extend %skeleton-animation;

            position: relative;
            background-color: $dirtyWhite;
            border: none;
            pointer-events: none;
            overflow: hidden;

            em {
                display: none;
            }

            @include respond-above(sm) {
                width: 126px;

                &:first-child {
                    width: 176px;
                }
            }
        }

        &.btn-calendar {
            margin-bottom: 20px;
            
            &:only-child {
                margin-bottom: 0;
            }

            @include respond-above(sm) {
                margin-bottom: 0;
            }
        }

        @include respond-above(sm) {
            width: auto;
            max-width: rem(270px);
            margin-bottom: 0;

            & + a {
                margin-left: 25px;
            }
        }
    }
}



@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}