.widget-highlight {
    $this-widget: &;

    position: relative;
    z-index: 10;

    &__container {
        @include container();
        @include content-container();
        margin-top: rem(40px);

        @include respond-above(lg) {
            margin-top: rem(60px);
        }

        .base-news-page & {
            margin-bottom: rem(62px);

            @include respond-above(lg) {
                margin-bottom: rem(126px);
            }
        }
    }

    &__top {

        #{$this-widget}__link {
            display: none;
        }

        + #{$this-widget}__content {
            margin-top: 16px;
        }

        &.label-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            #{$this-widget}__link {
                display: inline-flex;
            }
        }

        @include respond-above(lg) {
            display: flex;
            justify-content: space-between;

            #{$this-widget}__link {
                display: inline-flex;
            }

            +  #{$this-widget}__content {
                margin-top: 29px;
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: rem(16px);
        overflow: hidden;
        margin-bottom: 32px;
        position: relative;
    }

    &__bottom {
        @include respond-above(lg) {
            display: none;
        }

        .base-news-page & {
            display: none;
        }

    }

    &__label {
        @include t-label-big();

        display: inline-block;
        color: $black;

        .label-title & {
            color: $darkGray;

            @include respond-above(lg) {
                @include karbonRegular();
                font-size: rem(48px);
                line-height: rem(56px);
                letter-spacing: -1.5px;
                text-transform: none;
                color: $black;
            }
        }
    }

    &__link {
        color: $blue;
        display: inline-flex;
    }

    .layout-cards {
        padding: 0 !important;

        .elem-card {
            &:first-child {
                padding-top: 0 !important;
            }
            @include respond-above(lg) {
                padding-top: 0 !important;
                padding-bottom: 10px;
            }
        }
    }

    .card__layout--horizontal {
        width: 100%;

        .elem-card {
            padding: 0;
        }
    }
    

    #{$wcmmode} & {
        .card__layout--horizontal .elem-card__content {
            flex-direction: column;
        }

        .elem-card__event {
            width: 100%
        }
    }
}