.widget-gallery {
    $this-gallery: &;

    background: $white;
    padding-left: 0;
    padding-right: 0;

    &.p-b-def {
        padding-bottom: rem(120px);
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__title {
        padding-bottom: rem(32px);

        @include respond-above(lg) {
            padding-bottom: rem(56px);
        }
    }

    &__row {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(12);
        margin-bottom: rem(24px);
        //display: none;

        @include respond-above(md) {
            @include make-col(6);

            margin-bottom: rem(40px);
        }

        @include respond-above(lg) {
            @include make-col(4);
        }

        &.show {
            //display: block;
        }

        #{$wcmmode} & {
            display: block !important;
        }
    }

    &__link {
        display: block;
        border-radius: 16px;
        overflow: hidden;
        position: relative;

        @include mouse-only() {
            &:hover {
                #{$this-gallery}__item-overlay,
                .elem-icon-anim {
                    opacity: 1;
                }
            }
        }
    }

    &__item-image {
        height: 244px;
        object-fit: cover;
        overflow: hidden;
        
        img {
            width: 100%;
            height: 100%; 
            object-fit: contain;
        }
        @include respond-above(lg) {
            height: 277px;
        }
    }

    &__item-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        left: 0;
        background-color: $blue;
        transition: all 0.7s cubic-bezier(0.31, 0.76, 0.13, 1.01);
        z-index: 10;
    }

    .elem-icon-anim {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.7s cubic-bezier(0.31, 0.76, 0.13, 1.01);
        z-index: 11;
    }

    &__item-description {
        color: $black;
        margin-top: rem(12px);
        font-size: rem(20px);

        @include respond-above(lg) {
            font-size: rem(24px);
        }
    }

    .elem-cta-load-more {
        margin-top: 16px;
    }
}