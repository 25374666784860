.widget-form-info {
    $this: &;
    height: 100%;
    background-color: $white;
    color: $black;
    
    &__container {
        display: flex;
        flex-direction: column;
        max-width: 940px;
        margin: 0 auto;
        height: 100%;
        overflow: hidden;
        padding: 24px 0;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        //flex-grow: 1;
        //padding-bottom: rem(30px);
        overflow-y: auto;
        overflow-x: hidden;

        /*@include respond-above(lg) {
            padding: 0;
        }*/
    }

    &__form-title {
        font-size: rem(32px);
        line-height: rem(40px);
        padding: rem(20px) 0;
        text-align: center;

        .form-result & {
            padding-top: rem(50px);
        }
    }

    &__form-fields {
        padding-bottom: rem(45px);
        transition: opacity 500ms ease-out;

        @include respond-above(md) {
            padding-bottom: rem(64px);
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        @include respond-above(md) {
            min-width: 620px;
        }
    }

    &__form-fields-group {
        display: flex;
        width: 100%;
        min-width: 100%;
        max-width: 620px;
        padding: 0 rem(24px);
        flex-direction: column;
        //overflow-y: auto;
        flex-shrink: 0;
        border: none;
        justify-content: space-between;
        //transition: opacity 0.5s ease-out;
        //pointer-events: none;

        &:first-child #{$this}__input:first-child {
            padding-top: 0;
        }

        @include respond-above(md) {
            flex-direction: row;
            margin: 0 auto;
            padding: 0;

            &.vertical {
                flex-direction: column;
            }

            &:first-child #{$this}__input {
                padding-top: 0;
            }
        }

        #{$edit_mode} & {
            padding-bottom: 40px;
        }
    }

    &__input {
        position: relative;
        width: 100%;
        //margin: 0 auto;
        padding-top: rem(36px);

        label {
            @include karbonMedium();
    
            display: block;
            font-size: rem(14px);
            line-height: rem(18px);
            //text-transform: uppercase;
            letter-spacing: 2px;
            padding-bottom: rem(6px);
    
            span {
                margin-left: 6px;
            }
        }

        input[type=checkbox] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            & ~ label {
                @include karbonRegular();

                display: inline-block;
                position: relative;
                font-size: rem(18px);
                line-height: rem(19px);
                text-transform: none;
                letter-spacing: 0;
                padding-left: rem(44px);
                padding-bottom: 0;

                h1, h2, h3, h4, h5, h6, p {
                    margin: 0;
                }

                &:before {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    transform: translateY(-50%);
                    border-radius: 2px;
                    border: 2px solid $blue;
                }

                a {
                    color: $blue;
                    text-decoration: underline;
                    pointer-events: all;
                }
            }

            &:checked ~ label {
                &:before {
                    content: $icon-check;
                    font-family: "nexicorp" !important;
                    color: $white;
                    background-color: $blue;
                    font-size: rem(16px);
                    text-align: center;
                    border-top-width: 4px;
                    border-left-width: 3px;
                }
            }

            & ~ .error-text {
                bottom: rem(-20px);
            }
        }

        &.valid,
        &.error {
            input[type=date] {
                color: $black;

                &:before {
                    display: none;
                    content: '';
                }
            }
        }

        &.large {
            max-width: 620px;
        }

        &.padding-reduced {
            padding-top: rem(26px);
        }

        &.text {
            padding-top: rem(24px);

            h1, h2, h3, h4, h5, h6, p {
                font-size: rem(14px);
                line-height: rem(16px);
                color: #999;
                margin: 0;
            }

            a {
                color: $blue;
                text-decoration: underline;
            }

            @include respond-above(md) {
                //padding-top: rem(20px);
            }
        }

        .error-text {
            display: none;
            position: absolute;
            //bottom: rem(-24px);
            left: 0;
            padding-left: rem(17px);
            margin-top: 4px;
            font-size: rem(14px);
            line-height: rem(12px);
            color: $inputTextError;
            
            &:before {
                position: absolute;
                content: $icon-alert;
                font-family: "nexicorp" !important;
                font-size: rem(13px);
                top: 0;
                left: 0;
            }
        }

        &.error {

            label {
                &:before {
                    border-color: $inputBorderError !important;
                }
            }

            input[type=date]:before {
                content: '';
            }

            .error-text {
                display: block;
            }
        }

        &.recaptcha-container {
            position: absolute;
            padding: 0;
        }

        @include respond-above(md) {
            padding-top: rem(43px);
            max-width: 290px;
        }
    }

    &__input-wrapper {
        display: flex;
        border: 1px solid #000000;
        border-radius: 8px;
        padding: rem(8px) rem(16px);
        font-size: rem(18px);
        line-height: rem(24px);

        input,
        textarea {
            width: 100%;
            height: 25px;
            margin: 0;
            border: none;
            letter-spacing: -0.5px;

            &::-ms-clear {  // IE X button removal
                display: none;
                width : 0;
                height: 0;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $gray;
                opacity: .8;
            }
              
            &:-ms-input-placeholder {
                color: $gray;
                opacity: .8;
            }
              
            &::-ms-input-placeholder {
                color: $gray;
                opacity: .8;
            }
        }

        textarea {
            height: 94px;

            @include respond-above(md) {
                height: 168px;
            }
        }

        span {
            opacity: .5;
            margin-right: 5px;
            line-height: rem(25px);
        }

        .error & {
            border-color: $inputBorderError;
        }
    }

    &__form-result {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 40px;
        left: 0;
        right: 0;
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        transition: opacity 400ms ease-out;
        background-color: white;

        &.active,
        #{$edit_mode} & {
            opacity: 1;
            transition-duration: 500ms;
            transition-delay: 400ms;
            pointer-events: all;
        }

        #{$edit_mode} & {
            position: static;
            padding-top: 40px;
        }
    }

    &__form-icon {
        padding-bottom: rem(26px);
        text-align: center;
        color: $blue;

        em {
            font-size: rem(64px);
        }
    }

    &__form-message {
        //padding: rem(20px);
        font-size: rem(18px);
        line-height: rem(20px);
        text-align: center;
    }

    &__form-footer {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-around;

        @include respond-above(md) {
            max-width: 310px;
        }
    }

    &__form-btn {
        display: none;
        width: auto;
        min-width: 150px;
        min-height: 40px;
        padding-right: 30px;
        padding-left: 30px;

        &.active {
            display: flex;
        }

        &[disabled]:not(.sending) {
            background-color: #C9CAC8;
            border-color: #C9CAC8;
        }

        #{$edit_mode} & {
            &[data-role=next],
            &[data-role=back] {
                display: flex;
            }
        }

        @include respond-above(md) {
            min-width: 140px;
        }
    }
}