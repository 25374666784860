.elem-titleDetailPage {
    $this-title: &;

    width: 100%;

    &__wrapper {
        @include container();
        @include content-container();
    }
    

    &__content {
        padding: 40px 0;
        display: flex;
        flex-direction: row;

        @include respond-above(lg) {
            padding: 56px 0;
        }
    }

    &__content-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center;

        @include respond-below(lg){
            @include cut-words;
            margin-right: 17px;
            justify-content: center;
        }
    }

    &__date{
        @include t-label-big();
        
        color: $black;
        padding-bottom: 16px;
    }

    &__title {
        @include karbonRegular();
        font-size: rem(32px);
        letter-spacing: -1px;
        line-height: rem(40px);
        word-wrap:break-word;
        color: $black;

        @include respond-above(lg) {
            max-width:80%;
            font-size: rem(64px);
            line-height: rem(72px);
            letter-spacing: -2.5px;
        }

        +  #{$this-title}__subtitle,
        + #{$this-title}__description {
            margin-top: rem(16px);

            @include respond-above(lg) {
                margin-top: rem(24px);
            }
        }
    }

    &__subtitle {
        @include karbonRegular();
        font-size: rem(16px);
        line-height: rem(22px);
        color: $black;
        display: flex;
        
        @include respond-above(lg) {
            font-size: rem(24px);
            letter-spacing: -0.5px;
            line-height: rem(32px);
        }

        &-black {
            color: $black;

            @include respond-below(lg) {
                display: none;
            }
        }

        &-gray {
            color: $darkGray;

            @include respond-below(lg) {
                display: none;
            }
        }

        &-mobile {
            color: $darkGray;

            @include respond-above(lg) {
                display: none;
            }
        }

        + #{$this-title}__description {
            margin-top: rem(32px);
        }
    }

    &__description {
        @include karbonRegular();

        font-size: rem(16px);
        line-height: rem(22px);
        color: $darkGray;
        display: flex;

        @include respond-above(lg) {
            font-size: rem(24px);
            letter-spacing: -0.5px;
            line-height: 32px;
        }
    }
}


.elem-titleDetailPage_ctaBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;

        @include respond-above(lg) {
            display: none;
        }
}
